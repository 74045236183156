(function() {
  'use strict';

  angular.module('cgescola', [
  	'cgescola.config',
    'cgescola.inscricao',
    'cgescola.homologacao',
    'cgescola.coordenadorRegional',
    'cgescola.mensagens',
//    'cg-escola.autenticacao',
    'cgescola.avaliacao',
    'cgescola.recurso',
  	'ngCookies',
  	'ngTouch',
  	'ngSanitize',
  	'ngMessages',
  	'ngAria',
    'MassAutoComplete',
  	'ngResource',
  	'ngRoute',
  	'ui.router',
    'ui.select',
  	//'ui.bootstrap',
  	'mgcrea.ngStrap',
  	'ngTable',
  	'angular-loading-bar',
  	'angulartics',
  	'ui.select',
  	'monospaced.elastic',
    'LocalStorageModule',
  	//'jcs-autoValidate',
  	'ngFileUpload',
  	'textAngular',
  	'smoothScroll',
  	'ngStorage',
  	'underscore',
  	'infinite-scroll',
  	'ngFileSaver',
  	'checklist-model',
  	'vAccordion',
  	'ui.mask',
  	'ngMask',
    'file-model',
  	//'ui.bootstrap.datetimepicker',
  	'ui.dateTimeInput',
    'btorfs.multiselect',
    'exception',
    'toaster',
    'angularUtils.directives.dirPagination'
  ]);

  angular.module('cgescola').config([
    "$routeProvider",
    "$httpProvider",
    "$qProvider",
    function ($routeProvider, $httpProvider, $qProvider) {
        $httpProvider.defaults.headers.common = {};
        $httpProvider.defaults.headers.post = {};
        $httpProvider.defaults.headers.put = {};
        $httpProvider.defaults.headers.patch = {};
       //$httpProvider.defaults.withCredentials = true;
        $qProvider.errorOnUnhandledRejections(false);
    }
    ]);

})();
(function(){
  'use strict';

  angular.module('cg-escola.autenticacao', ['ngStorage']).run(run);

  /* @ngInject */
  function run($rootScope, TokenService, responseQueue){
    $rootScope.$on('auth:invalid-token-request', handleInvalidToken);

    function handleInvalidToken(){
      if(!TokenService.tokenRequest.isLocked()){
        // Cleans the invalid token from local storage
        TokenService.cleanToken();
        // Block new token requests while this is ongoing
        TokenService.tokenRequest.lock();
        // Request a token to the authentication server
        TokenService.getTokenFromRemote().then(function(tokenData){
          // Release service to new token requests because this one is solved
          TokenService.tokenRequest.release();
          // Retry all enqueued requests setting new Authorization header
          responseQueue.retryAll(function(config){
            var bearerToken = tokenData.tokenType + ' ' + tokenData.accessToken;
            config.headers['Authorization'] = bearerToken;
          });
        }, function(reason){
          // Reject all enqueued requests if wasn't possible to get a token
          responseQueue.rejectAll(reason);
        });
      }
    }
  }
})();

(function(){
  'use strict';

  angular.module('cgescola.avaliacao', []);

})();
(function(){
  'use strict';

  angular.module('cgescola.inscricao', []);

})();

(function(){
  'use strict';

  angular.module('cgescola.mensagens', []);

})();
(function(){
  'use strict';

  angular.module('cgescola.homologacao', []);

})();

(function(){
  'use strict';

  angular.module('cgescola.recurso', []);

})();
var exceptionApp = angular.module('exception', []);

exceptionApp.config([
    '$routeProvider',
    '$httpProvider',
    '$qProvider',
    function ($routeProvider, $httpProvider, $qProvider) {
        $httpProvider.interceptors.push('exceptionInterception');

    }
]);

exceptionApp.factory('exceptionInterception', ['$q', '$location', '$rootScope', function ($q, $location, $rootScope) {
    return {
        request: function (config) {

            if (config.url === 'info/info.html') {
                return config;
            }

            return config;
        },
        responseError: function (error) {
            if (error.status === 401 || error.status === 403) {
                $location.path('/');

            }
            if (error.status === 400) {

                if (error.data.Message != undefined) {
                    $rootScope.$broadcast("badRequest", error.data.Message);
                }

                if (error.data.error != undefined) {
                    $rootScope.$broadcast("badRequest", error.data.error);
                }

            }
            if (error.status === 500) {
                $rootScope.$broadcast("error500", error.data);
            }
            return $q.reject(error);
        },
        response:function(response){
            if (response.status === 200) {
                if(response.data === "INTERNAL_SERVER_ERROR"){
                    $rootScope.$broadcast("error500", error.data);
                }
            }
            return response;
        }
    };
}])
exceptionApp.directive('customException', function ($rootScope) {
    return {
        templateUrl: 'app/components/exception/custom-exception-tpl.html',
        restrict: 'A',
        link: function (scope, element, attrs) {

            $rootScope.$on('badRequest', function (evt, args) {
                scope.exceptionMessage = args;

            })

        }
    };
});
exceptionApp.run(function ($rootScope) {
    $rootScope.$on('error500', function (evt, args) {
        $rootScope.error500 = args;
        $('#modalException').modal();
    });
});

(function(){
  'use strict';

  angular.module('cgescola.coordenadorRegional', ['toaster']);

})();

angular.module("cgescola.config", [])
.constant("api", {"url":"http://app.homolog.estudantesdeatitude.go.gov.br/cgeescola"});

/* global moment:false */
(function() {
  'use strict';

  angular
    .module('cgescola')
    .constant('APP', { version: '1.0' });

})();

(function() {
  'use strict';

  angular.module('cgescola')
    .config(RouterConfig);

  /** @ngInject */
  function RouterConfig($routeProvider) {

    var templatesUrl = 'app/components';

    $routeProvider
      .when('/', {
          templateUrl: 'app/template/dashboard.html',
          controller: 'DashboardController',
          controllerAs: 'vm'
      }) 
        
      .when('/inscricao', {
          templateUrl:'app/template/inscricao/inscricao.html',
          controller: 'InscricaoController',
          controllerAs: 'vm'
      })

      .when('/inscricao/:idUsuario', {
        templateUrl:'app/template/inscricao/inscricao.html',
        controller: 'InscricaoController',
        controllerAs: 'vm'
      })

      .when('/inscricao-alunos', {
        templateUrl:'app/template/inscricao/inscricao-alunos.html',
        controller: 'InscricaoController',
        controllerAs: 'vm'
      })

      .when('/inscricao-alunos/:idUsuario', {
        templateUrl:'app/template/inscricao/inscricao-alunos.html',
        controller: 'InscricaoController',
        controllerAs: 'vm'
      })

      .when('/confirmar/:idEscola/:token', {
          templateUrl:'app/template/confirmar-inscricao.html',
          controller: 'MensagensController',
          controllerAs: 'vm'
      })    

      .when('/inscricoes', {
        templateUrl:'app/template/homologacao/menuEscolas.html',
        controller: 'HomologacaoInscricaoController',
        controllerAs: 'vm'
      })
       .when('/regional', {
        templateUrl:'app/template/regional/coordenacao-regional.html',
        controller: 'CoordenadorRegionalController',
        controllerAs: 'vm'
      })

      .when('/escolas-cadastradas', {
        templateUrl:'app/template/homologacao/homologar-inscricao.html',
        controller: 'HomologacaoInscricaoController',
        controllerAs: 'vm'
      })

      .when('/detalhamento-inscricao/:idDiretor', {
        templateUrl:'app/template/homologacao/homologar-inscricao-detalhamento.html',
        controller: 'HomologacaoInscricaoController',
        controllerAs: 'vm'
      })

      .when('/inscricao-confirmada', {
        templateUrl:'app/template/inscricao-realizada.html',
        controller: 'MensagensController',
        controllerAs: 'vm'
      })

      .when('/pre-inscricao-confirmada', {
        templateUrl:'app/template/pre-inscricao-concluida.html',
        controller: 'MensagensController',
        controllerAs: 'vm'
      })

      .when('/limite-atingido', {
        templateUrl:'app/template/limite-atingido.html'
      })

      .when('/avaliacao/:idEscola/:cpfDiretor',{
        templateUrl: 'app/template/avaliacao/avaliacao.html',
        controller: 'AvaliacaoController',
        controllerAs: 'vm'
      })

      .when('/recurso/:idEscola',{
        templateUrl: 'app/template/recurso/recurso.html',
        controller: 'RecursoController',
        controllerAs: 'vm'
      })

      .when('/gerenciamento-avaliacao',{
        templateUrl: 'app/template/avaliacao/gerenciamentoAvaliacao.html',
        controller: 'GerenciamentoAvaliacaoController',
        controllerAs: 'vm'
      })

      .when('/gerenciamento-recurso/:idEscola/:idEtapa',{
        templateUrl: 'app/template/recurso/gerenciamentoRecurso.html',
        controller: 'GerenciamentoRecursoController',
        controllerAs: 'vm'
      })

     .otherwise({ redirectTo: '/' });
  }

})();
/* global moment:false */
(function() {
  'use strict';

  angular
    .module('cgescola')
    .constant('APP', { version: '1.0' });

})();

(function(){
  'use strict';

  angular.module('cg-escola.autenticacao').config(config);

    /** @ngInject */
  function config($httpProvider) {
    $httpProvider.interceptors.push('TokenInterceptor');
  }

})();

angular.module('cgescola.avaliacao')
    .service('avaliacaoAPI', function ($http, api, $localStorage) {
        return {
            recuperarAvaliacao: recuperarAvaliacao,
            avaliar: avaliar,
            recuperarNotas: recuperarNotas,
            verificarAvaliacaoFinalizada: verificarAvaliacaoFinalizada,
            recuperarEscolasAvaliadas: recuperarEscolasAvaliadas,
            listarEscolasNaoAvaliadas: listarEscolasNaoAvaliadas,
            listarEscolasFaseEstadual: listarEscolasFaseEstadual,
            recuperarNomeEscola: recuperarNomeEscola,
            recuperarQtdeAlunos: recuperarQtdeAlunos,
            gerarRelatorioDeNotas: gerarRelatorioDeNotas,
            gerarRelatorioDeNotasAgrupamento: gerarRelatorioDeNotasAgrupamento,
            gerarRelatorioRanking: gerarRelatorioRanking,
            listarControleDeAvaliacao: listarControleDeAvaliacao,
            recuperarTipoEscolaPorCpf: recuperarTipoEscolaPorCpf,
           /* listarEtapasPorTipoDeEscola: listarEtapasPorTipoDeEscola,*/
            recuperarNotasTotalPorEtapa: recuperarNotasTotalPorEtapa,
            recuperarEtapa: recuperarEtapa,
            listarTodasEtapas: listarTodasEtapas,
            gerarArquivoZipado : gerarArquivoZipado,
            desabilitarFaseUpDown:desabilitarFaseUpDown,
            atualizarEscolaFaseEstadual: atualizarEscolaFaseEstadual,
            listaArquivos:listaArquivos,
            gerarAvaliacaoNotaAtividade:gerarAvaliacaoNotaAtividade,
            listaOcorrencia:listaOcorrencia
        };

        function recuperarAvaliacao(idEscola, idEtapa) {
            var request = {
                method: 'get',
                url: api.url + '/avaliacao/recuperar-avaliacoes',
                params: { idEscola: idEscola, idEtapa: idEtapa }
            }
            return $http(request);
        }

        function avaliar(lstAvaliacoes, rascunho, cpfAvaliador, etapaSelecionada, idEscola, tipoEscola, recursoDeferido) {
            console.log("API => ", lstAvaliacoes)
            var request = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                url: api.url + '/avaliacao/salvar-avaliacao',
                params: {
                    rascunho: rascunho,
                    cpfAvaliador: cpfAvaliador, idEtapa: etapaSelecionada,
                    idEscola: idEscola, tipoEscolaId: tipoEscola,
                    recursoDeferido: recursoDeferido
                },
                data: lstAvaliacoes
            }
            return $http(request);
        }

        function recuperarNotas(idEtapa, idEscola) {
            var request = {
                method: 'get',
                url: api.url + '/avaliacao/recuperar-notas-totais',
                params: { idEtapa: idEtapa, idEscola: idEscola }
            }
            return $http(request);
        }

        function verificarAvaliacaoFinalizada(idEscola, idEtapa) {
            var request = {
                method: 'get',
                url: api.url + '/recurso/listar-notas-definitivas',
                params: { idEscola: idEscola, idEtapa: idEtapa }
            }
            return $http(request);
        }

//        function recuperarEscolasAvaliadas(cpfAvaliador, idEtapa, nomeEscola, categoria, escolasComRecurso) {
//            var request = {
//                method: 'get',
//                url: api.url + '/gerenciamento-avaliacao/listar-escolas-avaliadas',
//                params: { cpfAvaliador: cpfAvaliador, idEtapa: idEtapa, nomeEscola: nomeEscola, categoria: categoria, escolasComRecurso: escolasComRecurso }
//            }
//            return $http(request);
//        }

        
        
        function recuperarEscolasAvaliadas(cpfAvaliador, idEtapa, nomeEscola, escolasComRecurso) {
            var request = {
                method: 'get',
                url: api.url + '/gerenciamento-avaliacao/listar-escolas-avaliadas',
                params: { cpfAvaliador: cpfAvaliador, idEtapa: idEtapa, nomeEscola: nomeEscola, escolasComRecurso: escolasComRecurso, cdRegional: $localStorage.cdRegional }
            }
            return $http(request);
        }
        
        
        function listarEscolasNaoAvaliadas(nomeEscola, idEtapa) {
            var request = {
                method: 'get',
                url: api.url + '/gerenciamento-avaliacao/listar-escolas-semAvaliacao',
                params: { nomeEscola: nomeEscola, idEtapa: idEtapa, cdRegional: $localStorage.cdRegional }
            }
            return $http(request);
        }
        
    
//        function listarEscolasNaoAvaliadas(nomeEscola, categoria, idEtapa) {
//            var request = {
//                method: 'get',
//                url: api.url + '/gerenciamento-avaliacao/listar-escolas-semAvaliacao',
//                params: { nomeEscola: nomeEscola, categoria: categoria, idEtapa: idEtapa }
//            }
//            return $http(request);
//        }

        function recuperarNomeEscola(idEscola) {
            var request = {
                method: 'get',
                url: api.url + '/escola/recuperar-nome-escola',
                params: { idEscola: idEscola }
            }
            return $http(request);
        }
        
        function recuperarQtdeAlunos(idEscola){
               var request = {
                method: 'get',
                url: api.url + '/escola/recuperar-qtde-alunos',
                params: { idEscola: idEscola }
            }
            return $http(request);
        }

        function gerarRelatorioDeNotas(idEscola) {
                var request = {
                    method: 'get',
                    url: api.url + '/gerenciamento-avaliacao/notas.csv',
                    params: { idEscola: idEscola }
                }
                return $http(request);
        }
        
        
        
         function gerarRelatorioDeNotasAgrupamento(idEscola, agrupamento) {
                var request = {
                    method: 'get',
                    url: api.url + '/gerenciamento-avaliacao/notas.csv',
                    params: { idEscola: idEscola, agrupamento:agrupamento }
                }
                return $http(request);
        }
        
             function gerarRelatorioRanking(cdRegional, idEtapa) {
                var request = {
                    method: 'get',
                    url: api.url + '/gerenciamento-avaliacao/ranking.csv',
                    params: { cdRegional: cdRegional, idEtapa:idEtapa }
                }
                return $http(request);
        }
        
        function gerarAvaliacaoNotaAtividade() {
                var request = {
                    method: 'get',
                    url: api.url + '/relatorios/escolas-notas-atividades.csv'  
                }
                return $http(request);
        }
        
        
        
        
        function gerarArquivoZipado() {
            var request = {
                method: 'get',
                url: api.url + '/arquivos/baixarArquivosDiretorioZipado'
                
            }
            request.responseType = 'blob';
            return $http(request);
    }
    
            function listaArquivos(cpfDiretor) {
                    var request = {
                        method: 'get',
                        url: api.url + '/arquivos/lista-arquivos',
                        params: { cpfDiretor: cpfDiretor }
                    }
                    return $http(request);
            }
            
             function listaOcorrencia(idEscola) {
                    var request = {
                        method: 'get',
                        url: api.url + '/ocorrencias/listar-ocorrencias',
                        params: { idEscola: idEscola }
                    }
                    return $http(request);
            }
            
            
    
    
    
        


/*        function listarControleDeAvaliacao(tipoEscola, idEtapa) {
            var request = {
                method: 'get',
                url: api.url + '/avaliacao/listar-controle-avaliacao',
                params: { tipoEscola: tipoEscola, idEtapa: idEtapa }
            }
            return $http(request);
        }
*/
        function listarControleDeAvaliacao(idEtapa) {
            var request = {
                method: 'get',
                url: api.url + '/avaliacao/listar-controle-avaliacao',
                params: { idEtapa: idEtapa }
            }
            return $http(request);
        }

        function recuperarTipoEscolaPorCpf(idEscola) {
            var request = {
                method: 'get',	
                url: api.url + '/escola/recuperar-tipo-escola-por-cpf',
                params: { idEscola: idEscola }
            }
            return $http(request)
        }

        /*function listarEtapasPorTipoDeEscola(tipoEscola) {
            var request = {
                method: 'get',
                url: api.url + '/avaliacao/lista-etapas-por-tipo-escola',
                params: { tipoEscola: tipoEscola }
            }
            return $http(request);
        }
        */
        function listarTodasEtapas() {
            var request = {
                method: 'get',
                url: api.url + '/avaliacao/lista-todas-etapas',
           }
            return $http(request);
        }

        function recuperarNotasTotalPorEtapa(idEtapa, idEscola) {
            var request = {
                method: 'get',
                url: api.url + '/avaliacao/recuperar-nota-total-por-etapa',
                params: { idEscola: idEscola, idEtapa: idEtapa }
            }
            return $http(request);
        }

        function recuperarEtapa(idEtapa) {
            var request = {
                method: 'get',
                url: api.url + '/avaliacao/recuperar-etapa',
                params: { idEtapa: idEtapa }
            }
            return $http(request);
        }
        
        
        
        function desabilitarFaseUpDown(desabilita) {
        	var request = {
                    method: 'post',
                    url: api.url + '/alteradata/mudadatafase',
                    params:{desabilita:desabilita}
                }
        	
        	return $http(request); 
        }
        
          function atualizarEscolaFaseEstadual(idEscola, status) {
        	var request = {
                    method: 'post',
                    url: api.url + '/escola/atualizar-escola-fase-estadual',
                    params:{idEscola: idEscola, status: status}
                }
        	
        	return $http(request); 
        }
        
        function listarEscolasFaseEstadual(status, cdRegional) {
            var request = {
                method: 'get',
                url: api.url + '/gerenciamento-avaliacao/listar-escolas-fase-estadual',
                params: { status:status,
                cdRegional:cdRegional}
            }
            return $http(request);
        }
        
        
        

    });
(function () {
    'use strict';

    angular.module('cgescola.avaliacao').controller('AvaliacaoController', AvaliacaoController);

    AvaliacaoController.$inject = ['$scope', 'avaliacaoAPI', '$routeParams', 'toaster', 'api', '$localStorage', 'inscricaoAPI', '$location', '$window', 'recursoAPI', 'FileSaver', 'dashboardAPI', '$http'];

    function AvaliacaoController($scope, avaliacaoAPI, $routeParams, toaster, api, $localStorage, inscricaoAPI, $location, $window, recursoAPI, FileSaver, dashboardAPI, $http) {
        var vm = this;
        vm.lstAvaliacao = [];
        vm.lstNotas = [];
        vm.arquivosEscola = [];
        vm.ocorrenciaEscola = [];
        vm.mostrarArquivos = false;
        vm.mostrarOcorrencia = false;

        if ($localStorage.isHomologador != undefined) {
            vm.homologador = $localStorage.isHomologador;
            vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined ||
                    $localStorage.cdRegional == null ||
                    $localStorage.cdRegional == "") ? true : false;
        }


        vm.verificarRecursoDeferido = function (idEtapa) {
            recursoAPI.verificarRecursoDeferido($routeParams.idEscola, idEtapa)
                    .then(function (obj) {
                        vm.recursoDeferido = obj.data;
                        if (vm.recursoDeferido == true) {
                            vm.bloquearBotoes = false;
                            vm.bloquearColuna1 = false;
                        }
                    })
        }

        vm.recuperarNomeEscola = function () {
            avaliacaoAPI.recuperarNomeEscola($routeParams.idEscola)
                    .then(function (obj) {
                        vm.nomeEscola = obj.data.NOME_ESCOLA;
                    })
        }

        vm.recuperarQtdeAlunos = function () {
            avaliacaoAPI.recuperarQtdeAlunos($routeParams.idEscola)
                    .then(function (obj) {
                        vm.qtdeAlunos = obj.data.qtdeAlunos;
                    })
        }


        vm.redirecionarParaDetalhamento = function () {
            $location.path('/detalhamento-inscricao/' + $routeParams.cpfDiretor);
        }

        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        vm.redirecionar = function () {
            $window.localStorage.clear();
            $location.path('/');
        }

        //link para a tela de homologação.
        vm.redirecionarHomologacao = function () {
            $location.path('/inscricoes');
        }
        vm.redirecionarRegional = function () {
            $location.path('/regional');
        }
        vm.redirecionarParaGerenciamento = function () {
            $location.path('/gerenciamento-avaliacao');
        }

        // Gera relatorio csv por escola
        vm.gerarRelatorioDeNotas = function () {
            avaliacaoAPI.gerarRelatorioDeNotas($routeParams.idEscola)
                    .then(function (resposta) {
                        if ($localStorage.isHomologador) {
                            var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                            FileSaver.saveAs(file, 'avaliacoes.csv');
                        }
                    })
        }

        vm.gerarRelatorioDeNotasPorEtapa = function () {
            avaliacaoAPI.gerarRelatorioDeNotasAgrupamento($routeParams.idEscola, "etapa")
                    .then(function (resposta) {
                        if ($localStorage.isHomologador) {
                            var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                            FileSaver.saveAs(file, 'avaliacoes.csv');
                        }
                    })
        }

        vm.toggleArquivos = function () {
            vm.mostrarArquivos = !vm.mostrarArquivos;
        }

        vm.toggleOcorrencia = function () {
            vm.mostrarOcorrencia = !vm.mostrarOcorrencia;
        }

        vm.statusOcorrencia = function (status) {

            var acao = "";
            switch (status) {
                case 2:
                    acao = "ENVIADA"
                    break;
                case 3:
                    acao = "RECUSADA"
                    break;
                case 4:
                    acao = "HOMOLOGADA"
                    break;
                case 5:
                    acao = "AGUARDANDO CONFIRMAÇÃO"
                    break;
                case 6:
                    acao = "UPLOAD DE ARQUIVO"
                    break;
                case 7:
                    acao = "GERENCIAMENTO ALUNOS"
                    break;
                case 8:
                    acao = "GERENCIAMENTO ESCOLA"
                    break;
            }
            return acao;
        }

        vm.formataDataOcorrencia = function (data) {
            var date = new Date(data);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        }


        vm.listaArquivos = function () {
            avaliacaoAPI.listaArquivos($routeParams.cpfDiretor)
                    .then(function (resposta) {
                        vm.arquivosEscola = resposta.data;
                    })
        }

        vm.listaOcorrencia = function () {
            avaliacaoAPI.listaOcorrencia($routeParams.idEscola)
                    .then(function (resposta) {
                        vm.ocorrenciaEscola = resposta.data;
                    })
        }


        vm.downloadArquivo = function (nomeDeArquivo) {
            inscricaoAPI.downloadDeArquivo(nomeDeArquivo)
                    .then(function (resposta) {
                        //$window.alert(resposta.data);
                        //$window.alert(resposta.data[0]);
//   	        	var file = new Blob([resposta.data], {type: 'application/octet-stream'});
                        var file = new Blob([resposta.data], {type: ''});
//   	        	var file = new Blob([resposta.data], {type: 'text/plain'});   	        	
//   	        	var file = new Blob([resposta.data], {type: 'application/pdf'});
                        FileSaver.saveAs(file, nomeDeArquivo);
                    })
        }

        /*        vm.listarControleDeAvaliacao = function (tipoEscola, idEtapa) {
         avaliacaoAPI.listarControleDeAvaliacao(tipoEscola, idEtapa)
         .then(function (obj) {
         vm.bloquearColuna1 = false;
         vm.bloquearBotoes = false;
         vm.controle = obj.data;
         
         vm.recuperarNotasTotalPorEtapa(idEtapa);
         vm.recuperarEtapa(idEtapa);
         $(vm.controle).each(function (i) {
         var pontuacao = 0;
         var lstPontuacao = [];
         
         for (pontuacao; pontuacao.toFixed(2) <= vm.controle[i].atividade.pontuacaoMaxima; pontuacao += 0.05) {
         lstPontuacao.push(pontuacao.toFixed(2));
         }
         vm.controle[i].atividade.lstPontuacao = lstPontuacao;
         })
         
         vm.recuperarAvaliacao($routeParams.idEscola, idEtapa);
         
         })
         }
         */



        vm.listarControleDeAvaliacao = function (idEtapa) {
            avaliacaoAPI.listarControleDeAvaliacao(idEtapa)
                    .then(function (obj) {
                        vm.bloquearColuna1 = false;
                        vm.bloquearBotoes = false;
                        vm.controle = obj.data;
                        vm.maximoPontuacaoPorEtapa = 0;
                        if (idEtapa == 2) {
                            vm.maximoPontuacaoPorEtapa = 100;
                        } else if (idEtapa == 4) {
                            vm.maximoPontuacaoPorEtapa = 200;
                        } else if (idEtapa == 3) {
                            vm.maximoPontuacaoPorEtapa = 300;
                        } else if (idEtapa == 5) {
                            vm.maximoPontuacaoPorEtapa = 20;
                        } else if (idEtapa == 6) {
                            vm.maximoPontuacaoPorEtapa = 380;
                        }
                        vm.recuperarNotasTotalPorEtapa(idEtapa);
                        vm.recuperarEtapa(idEtapa);
//                        $(vm.controle).each(function (i) {
//                            var pontuacao = 0;
//                            var lstPontuacao = [];
//                            
//                            for (pontuacao; pontuacao.toFixed(2) <= vm.controle[i].atividade.pontuacaoMaxima; pontuacao += 0.05) {
//                                lstPontuacao.push(pontuacao.toFixed(2));
//                            }
//                            vm.controle[i].atividade.lstPontuacao = lstPontuacao;
//                        })

                        vm.recuperarAvaliacao($routeParams.idEscola, idEtapa);

                    })
        }


        /*    vm.recuperarTipoEscolaPorCpf = function () {
         avaliacaoAPI.recuperarTipoEscolaPorCpf($routeParams.idEscola)
         .then(function (obj) {
         vm.tipoEscolaId = obj.data;
         $localStorage.tipoEscolaId = vm.tipoEscolaId;
         vm.listarTodasEtapas();
         vm.listarControleDeAvaliacao(vm.tipoEscolaId, $localStorage.idEtapa);
         })
         }*/

        vm.recuperarTipoEscolaPorCpf = function (idEtapa) {
            avaliacaoAPI.recuperarTipoEscolaPorCpf($routeParams.idEscola)
                    .then(function (obj) {
                        vm.tipoEscolaId = obj.data;
                        //$localStorage.tipoEscolaId = vm.tipoEscolaId;
                        vm.listarTodasEtapas();
                        /*vm.listarControleDeAvaliacao($localStorage.idEtapa);*/
                        vm.listarControleDeAvaliacao(idEtapa);
         
                    })
        }
        
        vm.teste = function(event, valorTotal){
            var valor = event.currentTarget.value.replace('.','').trim();
            var apenasNumeros = new RegExp('^[0-9]+$');
            if(apenasNumeros.test(valor)){
                
                if(valor.length == 2){
                        event.currentTarget.value = valor;
                }
                if(valor.length == 3){
                    var outros = '0';
                    if(valor.startsWith("0")){
                        outros = valor.substring(1,3);
                        event.currentTarget.value = '0.' + outros;
                    }else{
                         event.currentTarget.value = valor.substring(0,1) + '.' + valor.substring(1,3);
                    }

                }else if(valor.length == 4){
                    event.currentTarget.value = valor.substring(0,2) + '.' + valor.substring(2,4);
                }else if(valor.length == 5){
                    if(valor.startsWith("0")){
                        event.currentTarget.value = valor.substring(1);
                        vm.teste(event, valorTotal)
                    }else{
                        event.currentTarget.value = valor.substring(0,3) + '.' + valor.substring(3,5);
                    }
                }else if(valor.length > 5){
                    event.currentTarget.value = valor.substring(0,3) + '.' + valor.substring(3,5);
                }
//                console.log('valorTotal.length == 1 => ', valorTotal.toString().length)
//                console.log('valor => ', valor)
//                console.log('event.currentTarget.value.length > 1 ', event.currentTarget.value.length)
//                if(valorTotal.toString().length == 1 && event.currentTarget.value.length > 1){
//                   var a = event.currentTarget.value.replace('.','').trim();
//                    console.log('adf =>',a.substring(0,1) +'.' + a.substring(1,3));
//                    event.currentTarget.value =  a.substring(0,1) +'.' + a.substring(1,3)
//                    console.log('2 => ', event.currentTarget.value)
//                }
                if(parseFloat(valorTotal) < parseFloat(event.currentTarget.value)){
                    event.currentTarget.value = valorTotal;
                }
            }else{
                event.currentTarget.value = '';
            }

        }

        vm.listaDeAvaliacoesSelecionadas = function (idEtapa, idAtividade, avaliador, nota, nrAvaliacao) {
            var atividades = [];
                var outros = '0';
                    if(nota.startsWith("0")){
                        nota = nota.replace('.','').trim();
                        if(nota.length == 2){
                            nota = nota + ".00";
                        }else if(nota.length == 3){
                          nota = nota.substring(0,2) + "." + nota.substring(2,3) + "0";
                        }else if(nota.length == 4){
                            nota = nota.substring(0,2) + "." + nota.substring(2,4);
                        }
                         
                    }
            var avaliacao = {
                idEscola: parseInt($routeParams.idEscola)
                , cpfResponsavel: avaliador
                , etapaId: idEtapa
                , atividadeId: idAtividade
                , nota: parseFloat(nota)
                , numeroAvaliacao: nrAvaliacao
            }
 
            $(vm.lstAvaliacao).each(function (i) {
                if (vm.lstAvaliacao.length > -1) {
                    if (vm.lstAvaliacao[i].id != undefined && idAtividade == vm.lstAvaliacao[i].atividadeId) {
                        vm.lstAvaliacao[i].nota = avaliacao.nota;
                    }
                    atividades.push(vm.lstAvaliacao[i].atividadeId);
                }
            });

            var index = atividades.indexOf(avaliacao.atividadeId);
            if (index == -1) {
                vm.lstAvaliacao.push(avaliacao);
            }else{
                vm.lstAvaliacao[index].nota = avaliacao.nota;
            }

          
          }

        vm.verificarEtapa = function (etapaId) {
            $localStorage.idEtapa = etapaId;
            vm.idEtapa = $localStorage.idEtapa;

        }

        vm.avaliar = function (rascunho) {
            $('#btnRascunho').button('loading');
            $('#btnAvaliar').button('loading');
            $('#btnAvaliarRecurso').button('loading');
            console.log("controller => ", vm.lstAvaliacao)
            avaliacaoAPI.avaliar(vm.lstAvaliacao, rascunho, vm.usuarioLogado,
                    $localStorage.idEtapa, $routeParams.idEscola, vm.tipoEscolaId,
                    vm.recursoDeferido)
                    .then(function (obj) {

                        if (obj.data != "BAD_REQUEST") {
                            if (vm.idEtapa == undefined || vm.idEtapa == null || vm.idEtapa <= 1) {
                                vm.idEtapa = 2;
                            }
                            vm.recuperarNotasTotalPorEtapa(vm.idEtapa);
                            if (rascunho) {
                                vm.msgSuccess = "Rascunho salvo com sucesso";
                            } else {
                                vm.msgSuccess = "Avaliação salva com sucesso";
                            }

                            vm.recuperarNotaTotalDaEtapa(vm.idEtapa);
                            vm.recuperarTotalDeNotas($routeParams.idEscola);

                            $('#btnRascunho').button('reset');
                            $('#btnAvaliar').button('reset');
                            $('#btnAvaliarRecurso').button('reset');
                            toaster.pop({
                                type: 'success',
                                title: vm.msgSuccess,
                                body: '',
                                showCloseButton: true,
                                timeout: 5000
                            });
                            vm.recuperarAvaliacao($routeParams.idEscola, vm.idEtapa);

                            vm.verificarRecursoDeferido(vm.etapaId);
                        } else {
                            toaster.pop({
                                type: 'error',
                                title: "Preencha os campos!",
                                body: '',
                                showCloseButton: true,
                                timeout: 5000
                            });

                            $('#btnRascunho').button('reset');
                            $('#btnAvaliar').button('reset');
                            $('#btnAvaliarRecurso').button('reset');
                        }
                    })
        }

        vm.recuperarAvaliacao = function (idEscola, idEtapa) {
            avaliacaoAPI.recuperarAvaliacao(idEscola, idEtapa)
                    .then(function (obj) {
                        vm.lstAvaliacao = obj.data;
                        if (vm.lstAvaliacao.length > 0) {
                            vm.exibirAvaliador = true;
                            vm.nomeHomologadorAvaliador = vm.lstAvaliacao[0].nomeAvaliador;
                        } else {
                            vm.exibirAvaliador = false;
                        }
                        $scope.avaliacao = {};
                        $scope.avaliacao4 = {};
                        vm.verificarRecursoDeferido(idEtapa);

                        $(vm.lstAvaliacao).each(function (i) {
                            var a = vm.lstAvaliacao[i];
                            $scope.avaliacao[vm.lstAvaliacao[i].atividadeId] = vm.lstAvaliacao[i].nota;
                            vm.verificarBloqueioDeAvaliacao(vm.lstAvaliacao[i]);

                        })
                        vm.recuperarNomeDoAvaliador(vm.lstAvaliacao[0].cpfResponsavel)
                    })
        }

        /*   vm.listarEtapasPorTipoDeEscola = function (tipoEscola) {
         avaliacaoAPI.listarEtapasPorTipoDeEscola(tipoEscola)
         .then(function (obj) {
         vm.etapasPorTipoEscola = obj.data;
         })
         }*/

        vm.listarTodasEtapas = function () {
            avaliacaoAPI.listarTodasEtapas()
                    .then(function (obj) {
                        //vm.etapasPorTipoEscola = obj.data;
                        vm.etapasEscola = obj.data;
                    })
        }

        vm.recuperarNotasTotalPorEtapa = function (idEtapa) {
            avaliacaoAPI.recuperarNotasTotalPorEtapa(idEtapa, $routeParams.idEscola)
                    .then(function (obj) {
                        vm.notaTotal = obj.data;
                    })
        }

        vm.verificarBloqueioDeAvaliacao = function (avaliacao) {
            if (!vm.etapaDiferenciada) {
                if (!avaliacao.rascunho && avaliacao.numeroAvaliacao == 1 && avaliacao.etapaId == $localStorage.idEtapa && !vm.recursoDeferido) {
                    vm.bloquearColuna1 = false;
                    if (vm.usuarioLogado == avaliacao.cpfResponsavel && avaliacao.etapaId == $localStorage.idEtapa) {
                        vm.bloquearBotoes = false;
                    }
                }
                if (avaliacao.cpfResponsavel != vm.usuarioLogado && !vm.recursoDeferido) {
                    vm.bloquearBotoes = false;
                    vm.bloquearColuna1 = false;
                }
            }
        }

        vm.recuperarEtapa = function (etapaId) {
            avaliacaoAPI.recuperarEtapa(etapaId)
                    .then(function (obj) {
                        vm.etapaDiferenciada = obj.data.diferenciada;
                    })
        }

        vm.recuperarNomeDoAvaliador = function (cpf) {
            dashboardAPI.recuperarNomeDoUsuario(cpf)
                    .then(function (obj) {
                        if (obj.length != 0) {
                            vm.nomeAvaliador = obj[0].nome;
                        } else {
                            vm.nomeAvaliador = '';
                        }
                    })
        }

        vm.recuperarTotalDeNotas = function () {
            recursoAPI.recuperarTotalDeNotas($routeParams.idEscola)
                    .then(function (obj) {
                        vm.total = obj.data
                    })
        }

        vm.recuperarPontuacaoMaxima = function () {
            recursoAPI.recuperarPontuacaoMaxima()
                    .then(function (obj) {
                        vm.pontualMaximaPossivel = obj.data;
                    })
        }

        vm.recuperarPontuacaoMaximaPorEtapa = function (idEtapa) {
//            recursoAPI.recuperarPontuacaoMaximaPorEtapa(idEtapa, $localStorage.tipoEscolaId)
            recursoAPI.recuperarPontuacaoMaximaPorEtapa(idEtapa)
                    .then(function (obj) {
                        vm.pontuacaoMaximaEtapa = obj.data;
                    })
        }

        vm.recuperarNotaTotalDaEtapa = function (idEtapa) {
            recursoAPI.recuperarNotaTotalDaEtapa($routeParams.idEscola, idEtapa)
                    .then(function (obj) {
                        vm.totalPorEtapa = obj.data;
                        if (!vm.totalPorEtapa) {
                            vm.totalPorEtapa = 0;
                        }
                    })
        }

        vm.main = function () {
            //Seta o cabeçalho comum as chamadas http com o token para esta controller 
            $http.defaults.headers.common = {'Authorization': $localStorage.headerToken};
            vm.idEtapa = 2;
            vm.usuarioLogado = $localStorage.usuarioAutenticado;
            vm.recuperarPontuacaoMaximaPorEtapa(2);
            vm.recuperarNotaTotalDaEtapa(2);
            vm.recuperarTotalDeNotas();
            vm.recuperarPontuacaoMaxima();
            vm.recuperarNomeEscola();
            vm.recuperarTipoEscolaPorCpf(2);
            vm.recuperarQtdeAlunos();
            vm.listaArquivos();
            vm.listaOcorrencia();
        }

        vm.main();
    }
})();

(function () {
    'use strict';

   angular.module('cgescola.avaliacao').controller('GerenciamentoAvaliacaoController', GerenciamentoAvaliacaoController);

    GerenciamentoAvaliacaoController.$inject = ['$scope', 'avaliacaoAPI','inscricaoAPI','$routeParams', 'toaster', 'api', '$localStorage', '$location', 'homologacaoAPI', '$window', 'FileSaver'];

    function GerenciamentoAvaliacaoController($scope, avaliacaoAPI, inscricaoAPI, $routeParams, toaster, api, $localStorage, $location, homologacaoAPI, $window, FileSaver){
        var vm = this;
        vm.tipoRanking = "Geral";
        vm.regional ={};
        $scope.escolaSelecionadaFaseEstadual = {};
        $scope.regionais = [];
        
        vm.idEtapaSelecionado = null;
        if($localStorage.isHomologador != undefined) {
            vm.homologador = $localStorage.isHomologador;
             vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined || 
                $localStorage.cdRegional == null ||
                $localStorage.cdRegional == "" ) ? true : false;
        }
        $scope.regionalSelected = ''
        $scope.regionalTodasSelected = true;
         //paginação
        $scope.pagination = {
            current: 1
        };

        $scope.pageChangedEscola = function (newPage) {
            $scope.pagination.current = newPage;
        };

        $scope.paginationGerenciamento = {
            current: 1
        };

        $scope.pageChangedEscolaGerenciamento = function (newPage) {
            $scope.paginationGerenciamento.current = newPage;
        };
        
        
        $scope.paginationEscolasFaseEstadual = {
            current: 1
        };

        $scope.pageChangedEscolasFaseEstadual = function (newPage) {
            $scope.paginationEscolasFaseEstadual.current = newPage;
        };
        
        
        
        vm.recuperarRegionais =  function (){
             inscricaoAPI.recuperarRegionais()
                .then(function (obj) {
                     $scope.regionais = obj.data;
                })
            
            
        }
        
        vm.recuperarEscolasAvaliadas = function(cpfAvaliador, idEtapa, isGeral, nomeEscola, escolasComRecurso){
            vm.idEtapaSelecionado = idEtapa;
            vm.tipoRanking = vm.getNomeEtapa(idEtapa);
            if(isGeral){
                $('#geral').addClass('active');
                $('#pessoal').removeClass('active');
            }else{
                $('#geral').removeClass('active');
                $('#pessoal').addClass('active');
            }
            if(idEtapa == null){
                idEtapa = 1;
            }
            if(escolasComRecurso == null){
                escolasComRecurso = false;
            }
            
            avaliacaoAPI.recuperarEscolasAvaliadas(cpfAvaliador, idEtapa, nomeEscola, escolasComRecurso)
            .then(function(obj){     
                vm.escolasAvaliadas = obj.data;
                delete $scope.escolaComRecurso;
                delete $scope.nomeAvaliada;
                delete $scope.etapaFiltro;
                $('#pesquisarEscolasAvaliadas').modal('hide');
                $localStorage.idEtapa = idEtapa;
            })
            
        }
        
        vm.getNomeEtapa = function (idEtapa){
            switch (idEtapa) {
                case 1:
                    return "Formação do Time";
                    break;
                case 2:
                    return "Formação do Time";
                    break;
                case 3:
                    return "Auditoria Cívica";
                    break;
                case 4:
                    return "Tarefa Especial";
                    break;
                case 5:
                    return "Desafio - Planejamento";
                    break;
                case 6:
                    return "Desafio - Execução";
                    break;        
                default:
                      return "Geral";
                    break;
            }
        }
        
        

//        vm.recuperarEscolasHomologadas = function(nome, categoria, idEtapa){
//            if(idEtapa == null){
//                idEtapa = 0;
//            }
//            avaliacaoAPI.listarEscolasNaoAvaliadas(nome ,categoria, idEtapa)
//            .then(function(obj){
//                vm.escolasHomologadas = obj.data;
//                $('#pesquisarEscolas').modal('hide');
//            })
//        }
        
        
        vm.recuperarEscolasHomologadas = function(nome, idEtapa){
            if(idEtapa == null){
                idEtapa = 0;
            }
            avaliacaoAPI.listarEscolasNaoAvaliadas(nome ,idEtapa)
            .then(function(obj){
                            var values =  obj.data;
                      values = values.filter(function (a) {
                    return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
            }, Object.create(null))

            console.log(values)
            
                vm.escolasHomologadas = obj.data;
                $('#pesquisarEscolas').modal('hide');
            })
        }
        
        vm.recuperarEscolasFaseEstadual = function(status){
              avaliacaoAPI.listarEscolasFaseEstadual(status, vm.regional.codigo)
                      .then(function(obj){
                      vm.escolasFaseEstadual= obj.data;
              });
        };
        vm.confirmarEscolaFaseEstadual = function (escola){
            escola.status = !escola.status;
            avaliacaoAPI.atualizarEscolaFaseEstadual(escola.idEscola, escola.status)
            .then(function(obj){
                console.log(obj.data);
                if(obj.data == "OK"){
                toaster.pop({
                        type: 'success',
                        title: 'Alteração realizada com sucesso !!' ,    					
                        showCloseButton: true,
                        timeout: 5000
                            });
                
                }else{
                    toaster.pop({
                        type: 'error',
                        title: 'Não foi possível realizar a alteração',    					
                        showCloseButton: true,
                        timeout: 6000
                    });  
                }
                    $('#modalConfirmaFaseEstadual').modal('hide');
            })
        }
        
          vm.atualizarEscolaFaseEstadual = function (escola){
            $('#modalConfirmaFaseEstadual').modal('show');
            $scope.escolaSelecionadaFaseEstadual = escola;
//              escola.status = !escola.status;
        }
        
        vm.selecionarRegional = function (regional){
            var reg = { 
                nome: regional.nome,
                codigo:regional.codigo
            } 
            $scope.regionalSelected = reg
            
             vm.regional = reg;                    
        } 
        
      
        
   
        
        
        vm.listarEtapas = function(){
            var tipoEscola;

            avaliacaoAPI.listarTodasEtapas()
            .then(function(obj){
                vm.etapas = obj.data;
            })
        }
        
        

        //limpar scope utilizado no filtro de escolas.
   		vm.limparFiltro = function(){
   			delete $scope.nome;
            delete $scope.nomeAvaliada;
            delete $scope.etapaFiltro;
   		}

        //logout
        vm.redirecionar = function(){
            $window.localStorage.clear();
            $location.path('/');
        }

        //link para a tela de homologação.
        vm.redirecionarHomologacao = function(){
            $location.path('/inscricoes');
        }
        vm.gerarRelatorioRanking = function (){
         avaliacaoAPI.gerarRelatorioRanking($localStorage.cdRegional,vm.idEtapaSelecionado)
            .then(function(resposta){
                if ($localStorage.isHomologador) {
                    var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                      var nomeCsv = 'Ranking_' + vm.getNomeEtapa(vm.idEtapaSelecionado) + '.csv';
                    FileSaver.saveAs(file, nomeCsv);
                }
            })
        }
        
        vm.avaliacaoNotaAtividade = function (){
         avaliacaoAPI.gerarAvaliacaoNotaAtividade()
            .then(function(resposta){
                if ($localStorage.isHomologador) {
                    var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                    FileSaver.saveAs(file, 'avaliacoes-nota-atividade');
                }
            })
        }
        
        
        
      vm.redirecionarRegional = function () {
            $location.path('/regional');
        }
           // Gera relatorio csv por escola
        vm.gerarRelatorioDeNotas = function(){
            avaliacaoAPI.gerarRelatorioDeNotas(0)
            .then(function(resposta){
                if ($localStorage.isHomologador) {
                    var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                    FileSaver.saveAs(file, 'avaliacoes.csv');
                }
            })
        }
        
        vm.gerarArquivoZipado = function(){
        	vm.carregandoArquivo = true;
            avaliacaoAPI.gerarArquivoZipado()
            .then(function(resposta){
                if ($localStorage.isHomologador) {
                    var file = new Blob([resposta.data], {type: 'application/zip'});
                    FileSaver.saveAs(file, 'arquivos.zip');
                    
                }
            }).finally(function () {
            	vm.carregandoArquivo = false;
                 
            })
        }
        
        vm.desabilitarFaseUpDown = function(desabilita) {        	
            avaliacaoAPI.desabilitarFaseUpDown(desabilita)
            .then(function(obj) {            	
            	if (obj.data[0] == 'OK') {
            		if (obj.data[1] == 'true') {
            			toaster.pop({
        					type: 'success',
        					title: 'Desabilitada com sucesso !!' ,    					
        					showCloseButton: true,
        					timeout: 5000
        				});
            	} else {
            		toaster.pop({
    					type: 'success',
    					title: 'Habilitada com sucesso !!' ,    					
    					showCloseButton: true,
    					timeout: 5000
    				});
            	}
    				  				
    			} else {
    				toaster.pop({
    					type: 'error',
    					title: 'Erro ao alterar a data',    					
    					showCloseButton: true,
    					timeout: 6000
    				});    				
    			}
    		})            
        }
        

        vm.main = function(){
            vm.listarEtapas();
            vm.recuperarEscolasHomologadas(null, $localStorage.categoriaEscola, 0);
            vm.usuarioLogado = $localStorage.usuarioAutenticado;
            vm.recuperarRegionais();
        }

        vm.main();
    }
})();
(function () {
    'use strict';

    angular.module('cgescola.inscricao').controller('InscricaoController', InscricaoController);

    InscricaoController.$inject = ['$scope', 'inscricaoAPI', 'homologacaoAPI', '$localStorage', '$timeout', '$window', '$location', 'toaster', '$routeParams', 'api', 'recursoAPI', 'FileSaver'];

    function InscricaoController($scope, inscricaoAPI, homologacaoAPI, $localStorage, $timeout, $window, $location, toaster, $routeParams, api, recursoAPI, FileSaver ) {
        var vm = this;

//        vm.opcoesTipoEscola = [
//            {
//                codigo: 1,
//                nome: "ESCOLA CLASSE"
//            },
//            {
//                codigo: 2,
//                nome: "ENSINO FUNDAMENTAL"
//            },
//            {
//                codigo: 3,
//                nome: "ENSINO MÉDIO"
//            }
//        ];

        if ($localStorage.isHomologador != undefined) {
            vm.homologador = $localStorage.isHomologador;
             vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined || 
                $localStorage.cdRegional == null ||
                $localStorage.cdRegional == "" ) ? true : false;
        }

        //validações de orientadores
        vm.cpfRepetido = false;
        vm.atributoOrientadorInvalido = false;
        vm.atributoAlunoInvalido = false;
        vm.emailsDiferentes = false;

        $scope.alunos = [];
        $scope.orientadores = [];

        $scope.escola = [];
        $scope.escola.nomeEscola = '';
        vm.escolaSelecionada = false;
        vm.possuiPreCadastro = false;
        vm.bloquearTodosOsCampos = false;
        vm.bloquearInscricao = false;
        vm.esconderInscricao = false;
        vm.editar = false;
        vm.camposInvalidos = false;
        vm.semNotas = false;
        vm.maximoDeOrientadores = false;        
      
      
      // Nomes de arquivos para download       
      
       vm.nomesDeArquivos = [
      {          
          nome: ""
      },
      {        
          nome: "modelo_sugerido.pdf"
      },
      {        
          nome: "modelo_sugerido.dotx"
      },
      {
    	  nome: "Formulario_comprovacao_participacao.docx"
      },
      {
    	  nome: "Formulario_comprovacao_participacao.odt"
      },
      {
    	  nome: "Planejamento_do_desafio.docx"    		  
      },
      {
    	  nome: "Planejamento_do_desafio.odt"    		  
      },
      {
    	  nome: "Plano_de_acao_da_auditoria.docx"    		  
      },
      {
    	  nome: "Plano_de_acao_da_auditoria.odt"    		  
      },
      {
    	  nome: "Relatorio_Retorno_Auditoria_Civica.pptx"
      },
      {
    	  nome: "Relatorio_Retorno_Auditoria_Civica.odp"
      },
      {
    	  nome: "Resultado_do_Desafio.pptx"
      },
      {
    	  nome: "Resultado_do_Desafio.odp"
      }
    
  ];
       

        //gerar lista de séries
        $scope.lstSeriesParticipantes = '';

        //nome do diretor.
        $scope.nomeUsuario = $localStorage.nomeUsuario;
        
        // Verifica se diretor pode ou nao ja inscrever alunos. O prazo pe determinado pela CGE e salvo no BD
        vm.prazoDeInscricaoDeAlunosPeloDiretor = true;

        //adiciona o aluno na lista lista
        vm.addAluno = function () {
            $scope.alunos.push({
                nomeAluno: "",
                serieAluno: "",
                emailAluno: null,
                turnoAluno: "",
                telefone: null
            })
        }

        //remove o aluno na lista
        vm.removerAluno = function (aluno) {
            var index = $scope.alunos.indexOf(aluno);
            $scope.alunos.splice(index, 1);
        }

        //salva os dados de incrição
        vm.inscricao = function (series, rascunho, emailEscola, isHomologada) {
            vm.camposInvalidos = false;

            vm.atributoAlunoInvalido = false;

            var alunosMontado = [];

            if (!vm.camposInvalidos) {
                vm.gerarSeries(series);
          
                for (var x = 0; x < $scope.alunos.length; x++) {
                    if ($scope.alunos[x].nomeAluno == "" || $scope.alunos[x].nomeAluno == undefined || $scope.alunos[x].nomeAluno == "") {
                        vm.atributoAlunoInvalido = true;
                    }
                    if ($scope.alunos[x].serieAluno == "" || $scope.alunos[x].serieAluno == undefined || $scope.alunos[x].serieAluno == "") {
                        vm.atributoAlunoInvalido = true;
                    }
                    if ($scope.alunos[x].turnoAluno == "" || $scope.alunos[x].turnoAluno == undefined || $scope.alunos[x].turnoAluno == "") {
                        vm.atributoAlunoInvalido = true;
                    }if(series.length == 0 ){
                    	vm.atributoAlunoInvalido = true;
                    }
        
                    
                }

                if (vm.atributoAlunoInvalido) {
                    toaster.pop({
                        type: 'error',
                        title: 'Verifique se o nome, ano, serie e turno dos alunos foram preenchidos corretamente.',
                        body: '',
                        showCloseButton: true,
                        timeout: 5000
                    });
                }

                if (!vm.atributoAlunoInvalido) {
                    for (var w = 0; w < $scope.alunos.length; w++) {
                        $scope.alunos[w].serieAluno = $scope.alunos[w].serieAluno.codigo;
                        $scope.alunos[w].turnoAluno = $scope.alunos[w].turnoAluno.codigo;
                        alunosMontado.push($scope.alunos[w]);
                    }
                }

                if (!vm.atributoAlunoInvalido) {
                    if ($localStorage.isHomologador) {
                        vm.usuarioLogado = $scope.escola.cpfDiretor;
                    }
                    inscricaoAPI.inscricao($scope.lstSeriesParticipantes, alunosMontado, vm.usuarioLogado, rascunho, emailEscola, isHomologada)
                        .then(function (obj) {
                            if ((obj.data != "BAD_REQUEST" || obj.data != "INTERNAL_SERVER_ERROR") && !rascunho) {
                                toaster.pop({
                                    type: 'success',
                                    title: 'Dados dos alunos enviados com sucesso.',
                                    body: '',
                                    showCloseButton: true,
                                    timeout: 5000
                                });
                            }
                            if (obj.data == "BAD_REQUEST") {
                                toaster.pop({
                                    type: 'success',
                                    title: 'Verificar se todos os campos foram preenchidos. Somente o email do aluno pode ser vazio.',
                                    body: '',
                                    showCloseButton: true,
                                    timeout: 5000
                                });
                            } else
                                if (obj.data == "INTERNAL_SERVER_ERROR") {

                                } else {
                                    if (rascunho) {
                                        toaster.pop({
                                            type: 'success',
                                            title: 'Rascunho salvo com sucesso.',
                                            body: '',
                                            showCloseButton: true,
                                            timeout: 5000
                                        });
                                    } else {
                                        if (isHomologada != true) {
                                            $location.path('/inscricao-confirmada');
                                        } else {
                                            toaster.pop({
                                                type: 'success',
                                                title: 'Dados atualizados com sucesso.',
                                                body: '',
                                                showCloseButton: true,
                                                timeout: 5000
                                            });
                                        }
                                    }
                                }
                            vm.deletarListas();
                            vm.main();
                        })
                }
            }
        }

        vm.gerarInepAleatorio = function () {
            var newNumber = '';
            for (var a = 0; a < 8; a++) {
                newNumber = newNumber + (Math.floor(Math.random() * 9)).toString();
            }
            $scope.escola.codigoEscola = newNumber;
        }

        vm.deletarListas = function () {
            $scope.lstSeriesParticipantes = [];
        }

        //adiciona o orientador na lista lista
        vm.addOrientador = function () {
//            if ($scope.orientadores.length < 3) {
        	if ($scope.orientadores.length <= 2) {
                $scope.orientadores.push({
                    nomeOrientador: "",
                    matriculaOrientador: "",
                    numeroCpf: "",
                    telefoneOrientador: "",
                    emailOrientador: "",
                    funcaoOrientador: 1
                });
                // Desabilitar o botao de insercao de orientadres
                if ($scope.orientadores.length == 3) {
            		vm.maximoDeOrientadores = true;	
            	}
        	}
        }
        
        //remove o orientador na lista
        vm.removerOrientador = function (orientador) {
            var index = $scope.orientadores.indexOf(orientador);
            $scope.orientadores.splice(index, 1);
            vm.maximoDeOrientadores = false; // habilita botao de insercao de orientadores 
        }

        //salva os dados de pré-inscrição
//        vm.preInscricao = function (nomeInformado, enderecoEscola, telefoneEscola, emailEscola, emailEscolaConfirmado, regional, tipoEscola) {
        vm.preInscricao = function (nomeInformado, enderecoEscola, telefoneEscola, emailEscola, emailEscolaConfirmado, regional) {
            if ($scope.escola.codigoEscola == undefined || $scope.escola.codigoEscola == '') {
                vm.gerarInepAleatorio();
            }

            regional = regional.nome + ";" + regional.codigo;

            //tipoEscola = tipoEscola.codigo;

            var orientadoresMontados = [];
            var cpfsOrientadores = [];
            var qtdCpfArray = [];
            var cpfRepetido = false;

            vm.atributoOrientadorInvalido = false;
            vm.cpfRepetido = false;
            vm.emailsDiferentes = false;

            if ($scope.escolaObject == undefined) {
                $scope.escolaObject = $scope.escola.nomeEscola + ";" + $scope.escola.codigoEscola;
            }



            if (emailEscola != emailEscolaConfirmado) {
                vm.emailsDiferentes = true;
            }

            for (var j = 0; j < $scope.orientadores.length; j++) {
                if ($scope.orientadores[j].numeroCpf == "" || $scope.orientadores[j].numeroCpf == undefined || $scope.orientadores[j].numeroCpf == "") {
                    vm.atributoOrientadorInvalido = true;
                }
                if ($scope.orientadores[j].telefoneOrientador == "" || $scope.orientadores[j].telefoneOrientador == undefined || $scope.orientadores[j].telefoneOrientador == "") {
                    vm.atributoOrientadorInvalido = true;
                }
                if ($scope.orientadores[j].emailOrientador == "" || $scope.orientadores[j].emailOrientador == undefined || $scope.orientadores[j].emailOrientador == "") {
                    vm.atributoOrientadorInvalido = true;
                }
                if ($scope.orientadores[j].nomeOrientador == "" || $scope.orientadores[j].nomeOrientador == undefined || $scope.orientadores[j].nomeOrientador == "") {
                    vm.atributoOrientadorInvalido = true;
                }
//                if ($scope.orientadores[j].matriculaOrientador == "Não Informado" || $scope.orientadores[j].matriculaOrientador == undefined || $scope.orientadores[j].matriculaOrientador == "") {
//                    vm.atributoOrientadorInvalido = true;
//                }
                  cpfsOrientadores.push($scope.orientadores[j].numeroCpf);
            }

            for (var k = 0; k < cpfsOrientadores.length; k++) {
                var aux = 0;
                for (var l = 0; l < $scope.orientadores.length; l++) {
                    if (cpfsOrientadores[k] == $scope.orientadores[l].numeroCpf) {
                        aux += 1;
                    }
                    qtdCpfArray.push(aux);
                }
            }

            for (var z = 0; z < qtdCpfArray.length; z++) {
                if (qtdCpfArray[z] > 1) {
                    vm.cpfRepetido = true;
                    break;
                }
            }

            if (vm.emailsDiferentes) {
                toaster.pop({
                    type: 'error',
                    title: 'Os endereços de e-mail da escola informados são diferentes.',
                    body: '',
                    showCloseButton: true,
                    timeout: 6000
                });
            }

            if (vm.atributoOrientadorInvalido) {
                toaster.pop({
                    type: 'error',
                    title: 'Verifique se todas as informações dos orientadores foram preenchidas corretamente.',
                    body: '',
                    showCloseButton: true,
                    timeout: 6000
                });
            }

            if (vm.cpfRepetido) {
                toaster.pop({
                    type: 'error',
                    title: 'Os orientadores não podem possuir números de CPF repetidos.',
                    body: '',
                    showCloseButton: true,
                    timeout: 6000
                });
            }

            for (var i = 0; i < $scope.orientadores.length; i++) {
                orientadoresMontados.push($scope.orientadores[i]);
            }

            if (!vm.atributoOrientadorInvalido && !vm.cpfRepetido && !vm.emailsDiferentes) {
                if ($localStorage.isHomologador) {
                    vm.usuarioLogado = $scope.escola.cpfDiretor;
                }

//                inscricaoAPI.preInscricao(orientadoresMontados, nomeInformado, $scope.escolaObject, enderecoEscola, telefoneEscola, emailEscolaConfirmado, regional, vm.usuarioLogado, tipoEscola, vm.editar, vm.escolaId)
                inscricaoAPI.preInscricao(orientadoresMontados, nomeInformado, $scope.escolaObject, enderecoEscola, telefoneEscola, emailEscolaConfirmado, regional, vm.usuarioLogado, vm.editar, vm.escolaId)
                    .then(function (obj) {
                        if (vm.editar) {
                        	if(obj.data == "UNAUTHORIZED"){
                        		toaster.pop({
                                    type: 'error',
                                    title: 'NÃO FOI POSSÍVEL REALIZAR ESSA AÇÃO: CPF do diretor não cadastrado.',
                                    body: '',
                                    showCloseButton: true,
                                    timeout: 5000
                                });
                        	}else{
                        		toaster.pop({
                        			type: 'success',
                        			title: 'Dados da inscrição editados com sucesso.',
                        			body: '',
                        			showCloseButton: true,
                        			timeout: 5000
                        		});
                        	}
                        }
                        if (obj.data == "BAD_REQUEST") {
                            $scope.exceptionMessage = "Já existe uma escola cadastrada com esse código do INEP.";
                        }
                        if(obj.data == "UNAUTHORIZED"){
                    		toaster.pop({
                                type: 'error',
                                title: 'NÃO FOI POSSÍVEL REALIZAR ESSA AÇÃO: CPF do diretor não cadastrado.',
                                body: '',
                                showCloseButton: true,
                                timeout: 5000
                            });
                    		return;
                        }
                 
                       
                        if(obj.data == "CONFLICT"){
                            toaster.pop({
                                type: 'error',
                                title: "Professor já foi cadastrado por outra escola.\n\
                                O mesmo professor não pode ser orientador em mais de uma instituição.\n\
                                    Por favor, escolha outro orientador.",
                                body: '',
                                showCloseButton: true,
                                timeout: 5000
                            });
                    		return;
                                
                        }
        if (obj.data != "BAD_REQUEST" && !vm.editar && obj.data != "INTERNAL_SERVER_ERROR") {
                            $location.path('/pre-inscricao-confirmada');
                        }
                    })
            }
        }

        vm.redirecionarInscricao = function () {
            $location.path('/inscricao');
        }

        vm.redirecionarInscricaoAlunos = function () {
            $location.path('/inscricao-alunos/' + $routeParams.idUsuario);
        }

        vm.redirecionar = function () {
            $window.localStorage.clear();
            $location.path('/');
        }

        vm.redirecionarHomologacao = function () {
            $location.path('/inscricoes');
        }
      vm.redirecionarRegional = function () {
            $location.path('/regional');
        }
        vm.reenviarEmail = function (idEscola, emailEscola) {
            inscricaoAPI.reenviarEmail(idEscola, emailEscola)
                .then(function (obj) {
                    vm.reenviado = obj.data;
                })
        }

        vm.cancelarEdicao = function () {
            vm.main();
        }

        //verifica se o diretor já cadastrou alguma escola.
        vm.recuperarEscola = function (cpf) {
            inscricaoAPI.recuperarEscola(cpf)
                .then(function (obj) {
                    vm.qtdEscola = obj.data.length;
                    console.log(vm.qtdEscola);
                    if (obj.data.length != 0) {
                        $scope.escola = obj.data[0];
                        vm.escolaId = $scope.escola.id;
                        $scope.escolaAtiva = $scope.escola.ativo;
                        $scope.escola.emailEscolaConfirmado = $scope.escola.emailEscola;
                        //$scope.tipoEscola = { codigo: obj.data[0].tipoEscola, nome: obj.data[0].nomeTipoEscola };
                        //$localStorage.tipoEscola = obj.data[0].tipoEscola;
                        $scope.regional = { codigo: $scope.escola.codigoRegional, nome: $scope.escola.nomeRegional };
                        vm.possuiPreCadastro = true;
                        checarStatus($scope.escola.codigoStatus);
                        vm.recuperarTotalDeNotas($scope.escola.id);

                    } else {
                        vm.verificarPrazoLimite();
                        vm.listarStatusDasEscolas();
                        checarStatus(null);
                        vm.semNotas = true;
                        $scope.escolaAtiva = true;
                    }
                })
        }

        //verifica a quantidade de inscrições homologadas.
        vm.listarStatusDasEscolas = function () {
            homologacaoAPI.listarStatusDasEscolas()
                .then(function (obj) {
                    vm.qtdStatus = obj.data;
                });
        }

        //verifica o status atual da inscrição
        var checarStatus = function (status) {
            console.log(status);
            if (!status) {
                vm.bloquearInscricao = true;
            }
            if (status == 2) {
                vm.bloquearTodosOsCampos = true;
                vm.bloquearInscricao = true;
            }
            if ($scope.escola.codigoStatus == 5) {
                vm.esconderInscricao = true;
                vm.bloquearInscricao = true;
            }
            if ($scope.escola.codigoStatus == 4) {
                vm.bloquearTodosOsCampos = true;
            }
        }

        //lista as regionais de ensino
        vm.recuperarRegionais = function () {
            inscricaoAPI.recuperarRegionais()
                .then(function (obj) {
                    vm.regionais = obj.data;
                })
        }

        //lista as funções do orientador
        vm.recuperarFuncao = function () {
            inscricaoAPI.recuperarFuncao()
                .then(function (obj) {
                    vm.funcoes = obj.data;
                })
        }

        //lista as escolas de GOIAS
        vm.recuperarEscolas = function (nomeEscola) {    
            
            inscricaoAPI.recuperarEscolas(nomeEscola)
                .then(function (obj) {
                    vm.escolas = obj.data;
                    vm.results = [];
                    var q = nomeEscola.toLowerCase().trim();

                    for (var i = 0; i < vm.escolas.length && vm.results.length < 10; i++) {
                        var state = vm.escolas[i].nome;
                        var stateCodigoInep = vm.escolas[i].codigo;
                        var id = vm.escolas[i].codigo;
                        //if (state.toLowerCase().indexOf(q) === 0 || stateCodigoInep.toString().toLowerCase().indexOf(q) === 0)
                        vm.results.push({ label: state, value: state, id: id });
                    }
                })
               
            
        }

        //lista as séries
        vm.recuperarSeries = function () {
            inscricaoAPI.recuperarSeries()
                .then(function (obj) {
                    vm.series = obj.data;
                    vm.seriesFiltradas = vm.series;
                })
        }

        vm.recuperarSeriesFiltradas = function (series) {
            vm.gerarSeries(series);
            if (series.length > 0) {
                inscricaoAPI.recuperarSeriesFiltradas($scope.lstSeriesParticipantes)
                    .then(function (obj) {
                        vm.seriesFiltradas = obj.data;
                    })
            }
        }

        //lista os turnos
        vm.recuperarTurnos = function () {
            inscricaoAPI.recuperarTurnos()
                .then(function (obj) {
                    vm.turnos = obj.data;
                })
        }

//        vm.recuperarTipoEscola = function () {
//            inscricaoAPI.recuperarTipoEscola()
//                .then(function (obj) {
//                    vm.tipoEscolas = obj.data;
//                })
//        }

        //captura os dados digitados no autocomplete e busca sugestões no vm.escolas.
        vm.sugestaoEscola = function (term) {

            if (term.length > 2) {
                vm.recuperarEscolas(term);
                return vm.results;
            } else {
                return [];
            }
        }

        // indica as opções de escola de acordo com o dado digitado no autocomplete
        $scope.opcoesEscolas = {
            suggest: vm.sugestaoEscola,
            on_select: atribuirModel
        };

        //limpa os models ao apagar a escola selecionada no autocomplete
        vm.limparDadosDaEscola = function (escola) {
            if (escola.length == 1 || escola.length == 0) {
                delete $scope.escola.enderecoEscola;
                delete $scope.escola.telefoneEscola;
                delete $scope.escola.emailEscola;
                delete $scope.escola.cpfDiretor;
                delete $scope.regional;
                delete $scope.escola.codigoEscola;
                $scope.exceptionMessage = '';
                vm.results = [];
            }
        }

        // controla a visibilidade dos campos de pré-inscrição
        vm.editarDadosPreInscricao = function (e) {
            vm.liberarEdicaoPreInscricao = e;
            vm.editar = e;
            if (!e) {
                vm.main();
            }
        }

        // atribui a escola selecionada no autocomplete
        function atribuirModel(selected) {
            $scope.escola.nomeEscola = selected.value;
            $scope.escola.codigoEscola = selected.id;
            $scope.escolaObject = selected.value + ";" + selected.id;
            vm.escolaSelecionada = true;
            vm.recuperarDadosDaEscola($scope.escola.codigoEscola);

        }

        //recupera dados da escola ao selecionar um item no autocomplete
        vm.recuperarDadosDaEscola = function (codigo) {
            inscricaoAPI.recuperarDadosDaEscola(codigo)
                .then(function (obj) {
                    $scope.escola = obj.data[0];

                    $scope.escola.telefoneEscola = tratarTelefone($scope.escola.telefoneEscola);
                    $scope.escola.codigoEscola = $scope.escola.codigoInep;
                    $scope.regional = { codigo: $scope.escola.codigoRegional, nome: $scope.escola.nomeRegional };
                })
        }
        
        function tratarTelefone(telefone){
            console.log('tratarTelefone 1 => ',telefone)
            if(telefone.toString().length < 11){
                console.log('tratarTelefone 2 => ',telefone)
                return telefone = "0" + telefone ;
            }else{
                return telefone;
            }
        }

        //recupera lista de orientadores
        vm.recuperarListaDeOrientadores = function (cpf) {
            inscricaoAPI.recuperarListaDeOrientadores(cpf)
                .then(function (obj) {
                    if (obj.data.length > 0) {
                        $scope.orientadores = [];
                        $scope.orientadores = obj.data;
                        $($scope.orientadores).each(function (i) {
                            $scope.orientadores[i].Funcao = { codigo: obj.data[i].funcaoOrientador, nome: obj.data[i].descricaoFuncao };
                            $scope.orientadores[i].Matricula = obj.data[i].matriculaOrientador;
                            $scope.orientadores[i].Cpf = obj.data[i].numeroCpf;
                            $scope.orientadores[i].Email = obj.data[i].emailOrientador;
                            $scope.orientadores[i].Telefone = obj.data[i].telefoneOrientador;
                            $scope.orientadores[i].Nome = obj.data[i].nomeOrientador;
                        });
                    }
                })
        }

        //recupera lista de alunos
        vm.recuperarListaDeAlunos = function (cpf) {
            inscricaoAPI.recuperarListaDeAlunos(cpf)
                .then(function (obj) {
                    if (obj.data.length > 0) {
                        $scope.alunos = [];
                        $scope.alunos = obj.data;
                        $($scope.alunos).each(function (i) {
                            $scope.alunos[i].turnoAluno = { codigo: obj.data[i].turnoAluno, nome: obj.data[i].nomeTurno };
                            $scope.alunos[i].serieAluno = { codigo: obj.data[i].serieAluno, nome: obj.data[i].nomeSerie };
                            $scope.alunos[i].emailAluno = obj.data[i].emailAluno;
                            $scope.alunos[i].nomeAluno = obj.data[i].nomeAluno;
                        });
                    }
                })
        }

        //recupera lista de séries
        vm.recuperarListaDeSeries = function (cpf) {
            inscricaoAPI.recuperarListaDeSeries(cpf)
                .then(function (obj) {
                    if (obj.data.length > 0) {
                        $scope.series = obj.data;
                        $($scope.series).each(function (i) {
                            $scope.series[i] = { codigo: obj.data[i].codigoSerie, nome: obj.data[i].descricaoSerie };
                        });
                    }
                })
        }

        //gera lista de séries participantes para persistência
        vm.gerarSeries = function (series) {
            vm.lstSeries = [];
            $(series).each(function (i) {
                vm.lstSeries.push(series[i].codigo);
            });
            $scope.lstSeriesParticipantes = replaceAll(vm.lstSeries.toString(), ",", ";");
            $scope.lstSeriesParticipantes = $scope.lstSeriesParticipantes.substring(0, 1) == ";" ? $scope.lstSeriesParticipantes.substring(1) : $scope.lstSeriesParticipantes;
        }

        function escapeRegExp(str) {
            return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        }
        //Regex email
        function myFunction() {
            var x = document.getElementById("myEmail").pattern;
            document.getElementById("demo").innerHTML = x;
        }

        function replaceAll(str, find, replace) {
            return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
        }

        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });

        $(document).ready(function () {
            var ctrlDown = false, ctrlKey = 17, cmdKey = 91, vKey = 86, cKey = 67;

            $(document).keydown(function (e) {
                if (e.keyCode == ctrlKey || e.keyCode == cmdKey) ctrlDown = true;
            }).keyup(function (e) {
                if (e.keyCode == ctrlKey || e.keyCode == cmdKey) ctrlDown = false;
            });

            $(".bloquear-copiar-colar").keydown(function (e) {
                if (ctrlDown && (e.keyCode == vKey || e.keyCode == cKey)) return false;
            });
        });

        vm.restringirCaracteres = function (e) {
            if (e.which == 191) {
                e.preventDefault();
            }
        }

        vm.restringirCaracteresEmail = function (e) {
            if (e.which == 191 || e.which == 186 || e.which == 32) {
                e.preventDefault();
            }
        }

        vm.verificarPrazoLimite = function () {
            inscricaoAPI.verificarPrazoLimite()
                .then(function (obj) {
                    vm.prazoExcedido = obj.data;
                    if (vm.prazoExcedido == "FORBIDDEN" && vm.qtdEscola == 0 ) { 
                    	//&& $localStorage.usuarioAutenticado != "99999999999") {
                        $location.path('/limite-atingido');
                    }
                })
        }
        
        //Rever este metodo. FAZE-LO GENERICO DE FORMA que possamo utuliza-lo em todo o codigo atraves do parametro "nome da fase"
        vm.verificarPrazoLimiteDeInscricaoDeAlunosPeloOrientador = function () {
            inscricaoAPI.verificarPrazoLimiteDeInscricaoDeAlunosPeloOrientador ()
            .then(function (obj) {
                vm.prazoExcedido = obj.data;
                console.log('prazo => ', vm.prazoExcedido);
                if (vm.prazoExcedido == "FORBIDDEN") {
                	
                	// Creio que nao faça mais sentido a distincao entre orientador e diretor no caso de uploads/downloads
                	// e no caso de geranciamento de alunos. Rever o if abaixo depois
                	//if (!$localStorage.isDiretor) {
                    	//$location.path('/limite-atingido');
                	//} else { // Desabilita diretor botao de gerencriar alunos 
                		vm.prazoDeInscricaoDeAlunosPeloDiretor = false;
                //	} 
                	 
                } 
                
            })
       }
     
        vm.uploadArquivo = function (cpf,codigoInep,emailEscola) {  
            console.log('cpf => ', cpf)
            console.log('codigoInep => ',codigoInep)
        	var arquivo = document.getElementById("singleFileUploadInput");
            var resp = inscricaoAPI.uploadDeArquivo(arquivo.files[0], cpf, codigoInep, emailEscola);
            
        	
        	resp.then(function(obj){
                var spanNameFile  = document.getElementById('file-name');
                spanNameFile.textContent = '';
        		if(obj.data == 'OK'){
        				var spanSucessSendFile = document.getElementById("success-send");
        				spanSucessSendFile.textContent = 'O arquivo '+ arquivo.files[0].name  +  ' foi enviado com sucesso';
        				
        			}else{
        	        	var spanErrorSendFile = document.getElementById("error-send");
        	        	spanErrorSendFile.textContent = 'Não foi possível enviar o arquivo ' +  arquivo.files[0].name;

        			}
        			        		
                })
                .catch(function (err){
                        var spanErrorSendFile = document.getElementById("error-send");
        	        	spanErrorSendFile.textContent = 'Não foi possível enviar o arquivo ' +  arquivo.files[0].name;
                })
        	
        	
        	
        }        

        vm.downloadArquivo = function (nomeDeArquivo) {        	
        	inscricaoAPI.downloadDeArquivoCronograma(nomeDeArquivo)
        	.then(function(resposta) {
   	        	//$window.alert(resposta.data);
   	        	//$window.alert(resposta.data[0]);
//   	        	var file = new Blob([resposta.data], {type: 'application/octet-stream'});
   	        	var file = new Blob([resposta.data], {type:''});
//   	        	var file = new Blob([resposta.data], {type: 'text/plain'});   	        	
//   	        	var file = new Blob([resposta.data], {type: 'application/pdf'});
                FileSaver.saveAs(file, nomeDeArquivo);   	          
   	        })
        }
        
        vm.redirecionarParaRecurso = function (idEscola) {
            $location.path('/recurso/' + idEscola);
        }

        vm.recuperarTotalDeNotas = function (idEscola) {
            recursoAPI.recuperarTotalDeNotas(idEscola)
            .then(function(obj){
                if(!obj.data){
                    vm.semNotas = true;
                }
            })
        }

        vm.labels = {
            "itemsSelected": "Itens Selecionados ",
            "selectAll": "Marcar Todos",
            "unselectAll": "Desmarcar Todos",
            "search": "Pesquisar",
            "select": "Selecionar"
        }

        //funções que devem ser executadas ao abrir a página
        vm.main = function () {
            vm.usuarioLogado = $localStorage.usuarioAutenticado;

            /*
               Status : 1 - Inciada / 2 - Enviada / 3 - Recusada / 4 - Homologada 
            */           

//            if (!$localStorage.isDiretor && vm.usuarioLogado != "99999999999" && !$localStorage.isOrientador && !$localStorage.isHomologador) {
//                $location.path('/inscricoes');
//            }

            if ($localStorage.usuarioAutenticado != undefined) {            	
            	
				//$window.alert(" autenticado" );
                if (!$localStorage.isHomologador) {
                    if (!$localStorage.isOrientador) {
                    	vm.verificarPrazoLimiteDeInscricaoDeAlunosPeloOrientador();
                        vm.recuperarEscola($localStorage.usuarioAutenticado);
                        vm.recuperarListaDeAlunos($localStorage.usuarioAutenticado);
                        vm.recuperarListaDeOrientadores($localStorage.usuarioAutenticado);
                        vm.recuperarListaDeSeries($localStorage.usuarioAutenticado);
                    }
                    if ($localStorage.isOrientador) {
                    	//$window.alert(" autenticado" );
                    	vm.verificarPrazoLimiteDeInscricaoDeAlunosPeloOrientador();
                    	if (!$localStorage.isDiretorEOrientador) { // somente orientador .. nao é diretor .. neste caso deve-se desablitar a edicao de alguns campos  
                    		vm.orientadorAutenticado = true;
                    	}                        
                        vm.usuarioLogado = $localStorage.idDiretor;
                        vm.recuperarEscola($localStorage.idDiretor);
                        vm.recuperarListaDeAlunos($localStorage.idDiretor);
                        vm.recuperarListaDeOrientadores($localStorage.idDiretor);
                        vm.recuperarListaDeSeries($localStorage.idDiretor);                        
                    }
                }else{
                    if($routeParams.idUsuario){
                        vm.verificarPrazoLimiteDeInscricaoDeAlunosPeloOrientador();
                        vm.recuperarEscola($routeParams.idUsuario);
                        vm.recuperarListaDeAlunos($routeParams.idUsuario);
                        vm.recuperarListaDeOrientadores($routeParams.idUsuario);
                        vm.recuperarListaDeSeries($routeParams.idUsuario);
                    }
                    vm.semNotas = true;
                    $scope.escolaAtiva = true;
                }
            } else {
				//$window.alert(" indefinido usuario" ); 
				vm.redirecionar(); }

            vm.recuperarSeries();
            vm.recuperarFuncao();
            vm.recuperarRegionais();
            vm.recuperarTurnos();
            //vm.recuperarTipoEscola();            
        }

        vm.main();
    }
})();

angular.module('cgescola.inscricao')
.service('inscricaoAPI', function ($http, api, $localStorage) {
    return {
        preInscricao: preInscricao,
        inscricao: inscricao,
        recuperarRegionais: recuperarRegionais,
        recuperarEscolas: recuperarEscolas,
        recuperarSeries: recuperarSeries,
        recuperarEscola: recuperarEscola,
        recuperarTurnos: recuperarTurnos,
        recuperarDadosDaEscola:recuperarDadosDaEscola,
        recuperarFuncao: recuperarFuncao,
        recuperarListaDeAlunos: recuperarListaDeAlunos,
        recuperarListaDeOrientadores : recuperarListaDeOrientadores,
        recuperarListaDeSeries: recuperarListaDeSeries,
        reenviarEmail: reenviarEmail,
        recuperarSeriesFiltradas: recuperarSeriesFiltradas,
        verificarPrazoLimite: verificarPrazoLimite,
        verificarPrazoLimiteDeInscricaoDeAlunosPeloOrientador: verificarPrazoLimiteDeInscricaoDeAlunosPeloOrientador,
        uploadDeArquivo:uploadDeArquivo,
        downloadDeArquivo:downloadDeArquivo,
        downloadDeArquivoCronograma:downloadDeArquivoCronograma
        //recuperarTipoEscola: recuperarTipoEscola
    };

    function inscricao(_series, _alunos, _cpf, _rascunho, _emailEscola, _isHomologada){
    	var request = {
    		method: 'post',
    		headers: {
                'Content-Type': 'application/json'
            },
    		url: api.url + '/escola/salvar-time-alunos',
    		params: {
            seriesParticipantes: _series,
            cpf: _cpf,
            rascunho: _rascunho,
            emailEscola:_emailEscola,
            isHomologada: _isHomologada
            },
            data: _alunos
    	}
    	return $http(request);
    }

//    function preInscricao(_orientadores, _nomeInformado, _escola,
//     _enderecoEscola, _telefoneEscola, _emailEscola,
//      _regional, _cpf, _tipoEscola, _editar, _idEscola){
    function preInscricao(_orientadores, _nomeInformado, _escola,
    	     _enderecoEscola, _telefoneEscola, _emailEscola,
    	      _regional, _cpf, _editar, _idEscola){
        var request = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            url: api.url + '/escola/salvar-inscricao-escola',
            params: {nomeInformado: _nomeInformado, escola: _escola, enderecoEscola: _enderecoEscola,
                telefoneEscola: _telefoneEscola, emailEscola: _emailEscola, regional: _regional, cpf: _cpf, editar: _editar, idEscola: _idEscola},
                data: _orientadores
//            params: {nomeInformado: _nomeInformado, escola: _escola, enderecoEscola: _enderecoEscola,
//            telefoneEscola: _telefoneEscola, emailEscola: _emailEscola, regional: _regional, cpf: _cpf, tipoEscola: _tipoEscola, editar: _editar, idEscola: _idEscola},
//            data: _orientadores
        }
        return $http(request);
    }

    function recuperarEscola(_cpf){
        var request = {
            method: 'get',
            url: api.url + '/escola/recuperar-escola-cpfdiretor',
            params: {cpfDiretor:_cpf}
        }
        return $http(request);
    }

    function recuperarFuncao(){
        var request = {
            method: 'get',
            url: api.url + '/escola/listaFuncoes'     
        }
        return $http(request);
    }

    function recuperarListaDeAlunos(_cpf){
        var request = {
            method: 'get',
            url: api.url + '/escola/recuperar-inscricao-alunos' ,
            params: {cpfDiretor:_cpf}    
        }
        return $http(request);
    }

    function recuperarListaDeSeries(_cpf){
        var request = {
            method: 'get',
            url: api.url + '/escola/recuperar-series-participantes' ,
            params: {cpfDiretor:_cpf}    
        }
        return $http(request);
    }

    function recuperarListaDeOrientadores(_cpf){
        var request = {
            method: 'get',
            url: api.url + '/escola/recuperar-inscricao-orientadores' ,
            params: {cpfDiretor:_cpf}    
        }
        return $http(request);
    }

    function recuperarDadosDaEscola(_codigoEscola){
        var request = {
            method: 'get',
            url: api.url + '/escola/recuperar-escola-codigo',
            params: {codigoEscola:_codigoEscola}
        }
        return $http(request);
    }

    function recuperarRegionais(){
    	var request = {
            method: 'get',
            url: api.url + '/escola/listaRegionais',
        }
        return $http(request);
    }

    function recuperarEscolas(nomeEscola){
        var request = {
            method: 'get',
            url: api.url + '/escola/listaEscolas',
            params: {nomeEscola: nomeEscola, cdRegional: $localStorage.cdRegional}
        }
        return $http(request);
    }

    function recuperarSeries(){
        var request = {
            method: 'get',
            url: api.url + '/alunos/series',
        }
        return $http(request);
    }

    function recuperarSeriesFiltradas(_series){
        var request = {
            method: 'get',
            url: api.url + '/alunos/series-filtradas',
            params:{series: _series}
        }
        return $http(request);
    }

    function recuperarTurnos(){
        var request = {
            method: 'get',
            url: api.url + '/alunos/turnos',
        }
        return $http(request);
    }

    function reenviarEmail(_idEscola, _emailEscola){
        var request = {
            method: 'post',
            url: api.url + '/escola/enviar-email',
            params:{idEscola:_idEscola, emailEscola: _emailEscola}
        }
        return $http(request);
    }

    function verificarPrazoLimite(){
        var request = {
            method: 'get',
            url: api.url + '/escola/verificar-prazo-limite',
        }
        return $http(request);
    }

    
   // Alterar o nome e assinatura do metodo para conteplar um metodo generico 
    function verificarPrazoLimiteDeInscricaoDeAlunosPeloOrientador () {
    	var request = {
                method: 'get',
                url: api.url + '/escola/verificar-prazo-limite-de-inscricao-de-alunos-pelo-orientador',
            }
            return $http(request);

    }
    
    
    function uploadDeArquivo(arquivo, cpf, codigoInep, emailEscola) {
    	var formData = new FormData();
    	formData.append('arquivo',arquivo);
    	formData.append('cpfDiretor',cpf);
    	formData.append('codigoInep',codigoInep);
    	formData.append('emailEscola',emailEscola);
    	    	var request = {
    			data: formData,
                method: 'post',
                url: api.url + '/arquivos/salvarArquivo',                
                
            }
            return $http(request);
    }   
    
    
    function downloadDeArquivo(nomeDeArquivo){
    	
    	var request = {
                method: 'get',
                url: api.url + '/arquivos/baixarArquivo/'+nomeDeArquivo,
            }
    		request.responseType = 'blob';    	
            return $http(request);
    }
    
      function downloadDeArquivoCronograma(nomeDeArquivo){
    	
    	var request = {
                method: 'get',
                url: api.url + '/arquivos/baixarArquivoDownload/'+nomeDeArquivo,
            }
    		request.responseType = 'blob';    	
            return $http(request);
    }
    
//    function downloadDeArquivo(nomeDeArquivo){    	
//    	var request = {
//                method: 'get',
//                url: api.url + '/arquivos/baixarArquivo',
//                params:{nome_arquivo:nomeDeArquivo}
//            }
//            return $http(request);
//    }
    

    
//    function recuperarTipoEscola(){
//        var request = {
//            method: 'get',
//            url: api.url + '/escola/recuperar-tipo-escola',
//        }
//        return $http(request);
//    }
});
(function () {
    'use strict';

    angular.module('cgescola.mensagens').controller('MensagensController', MensagensController);

    MensagensController.$inject = ['$scope', '$location', '$routeParams', 'dashboardAPI', '$localStorage','$window'];

    function MensagensController($scope,$location, $routeParams, dashboardAPI, $localStorage, $window){
		var vm = this;
		
		if($localStorage.isHomologador != undefined) {
            vm.homologador = $localStorage.isHomologador;
             vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined || 
                $localStorage.cdRegional == null ||
                $localStorage.cdRegional == "" ) ? true : false;
        }

    	vm.redirecionar = function(){
    		$location.path('/inscricao');
    	}

        //utilizado para verificar se o usuário já clicou no link de confirmação da pré-inscrição
    	vm.confirmarPreInscricao = function(){    		
            dashboardAPI.confirmarPreInscricao($routeParams.idEscola, $routeParams.token)
            .then(function(obj){
            	if(obj.data == "UNAUTHORIZED") {
            		
            		//$window.alert("Usuario nao autorizado");
            		vm.usuarioNaoAutorizado = true;
            		//$location.path('/');
            	} else {
            		vm.usuarioNaoAutorizado = false;
            	} 
            })
        }

    	vm.main = function(){
//    		 if($localStorage.usuarioAutenticado == undefined && $location.path() != '/confirmar/' + $routeParams.idEscola){
    			 if($localStorage.usuarioAutenticado == undefined && $location.path() != '/confirmar/' + $routeParams.idEscola +'/'+$routeParams.token){    				 
    				 
    				 //$window.alert(" Redireio"+$routeParams.token);
    		 	$location.path('/');
    		 }
//      		if($location.path() == '/confirmar/' + $routeParams.idEscola){    			 
    			 if($location.path() == '/confirmar/' + $routeParams.idEscola +'/'+ $routeParams.token){
    				 var t = $routeParams.token;
    				 //$window.alert(" PER"+t);
                vm.confirmarPreInscricao();
            }
    	}
    	vm.main();
	}

    })();
angular.module('cgescola.homologacao')
.service('homologacaoAPI', function ($http, api,$localStorage) {
 	return {
        recuperarEscolas: recuperarEscolas,
        homologarInscricao:homologarInscricao,
        listarStatusDasEscolas: listarStatusDasEscolas,
        recuperarNomeResponsavel: recuperarNomeResponsavel,
        cancelarInscricao: cancelarInscricao,
        gerarRelatorioInscricoes: gerarRelatorioInscricoes,
        gerarRelatorioEscolasCadastradas:gerarRelatorioEscolasCadastradas,
        gerarRelatorioEscolasAlunosCadastradas:gerarRelatorioEscolasAlunosCadastradas,
        gerarRelatorioEscolasOrientadoresCadastradas:gerarRelatorioEscolasOrientadoresCadastradas
        //recuperarCategoriasEscola: recuperarCategoriasEscola
    };

    function recuperarEscolas(_nome,_status, _categoria){
        var request = {
            method: 'get',
            url: api.url + '/escola/recuperar-escolas-cadastradas',
            params: {nomeEscola:_nome, status:_status, categoria: _categoria, cdRegional:$localStorage.cdRegional}
        }
        return $http(request);
    }

    function homologarInscricao(_recusar, _motivacao, _idEscola, _emailDiretor, _emailEscola, _cpfResponsavel){
         var request = {
            method: 'post',
            url: api.url + '/escola/homologar-inscricao-escola',
            params: {recusar:_recusar, motivacao: _motivacao, idEscola: _idEscola
            , emailDiretor: _emailDiretor, emailEscola: _emailEscola, cpfResponsavel:_cpfResponsavel}
        }
        return $http(request);
    }

    function cancelarInscricao(_idEscola, _cpfResponsavel) {
        var request = {
            method: 'post',
            url: api.url + '/escola/cancelar-inscricao-escola',
            params: {codigoEscola: _idEscola, cpfResponsavel: _cpfResponsavel}
        }
        return $http(request);
    }

    function listarStatusDasEscolas(_categoria){
        var request = {
            method: 'get',
            url: api.url + '/escola/recuperar-qtd-status',
            params: {categoria: _categoria, codInstituicao:$localStorage.cdRegional}
        }
        return $http(request);
    }

    function recuperarNomeResponsavel(_cpfDiretor){
        var request = {
            method: 'get',
            url: api.url + '/autenticar/recuperar-servidor',
            params: {cpfDiretor: _cpfDiretor}
        }
        return $http(request);
    }

    function gerarRelatorioInscricoes() {
        var request = {
            method: 'get',
            url: api.url + '/relatorios/inscricoes.csv'            
        }
        return $http(request);
    }    
    
     function gerarRelatorioEscolasCadastradas() {
        var request = {
            method: 'get',
            url: api.url + '/relatorios/escolas-cadastradas.csv'            
        }
        return $http(request);
    }   
    
    function gerarRelatorioEscolasAlunosCadastradas(idEscola) {
        var request = {
            method: 'get',
            url: api.url + '/relatorios/escolas-cadastradas-alunos.csv',
            params: {idEscola: idEscola}
        }
        return $http(request);
    }   
    
    function gerarRelatorioEscolasOrientadoresCadastradas(idEscola) {
        var request = {
            method: 'get',
            url: api.url + '/relatorios/escolas-cadastradas-orientadores.csv',
            params: {idEscola: idEscola}
        }
        return $http(request);
    }   
    
    
    
    
//    function recuperarCategoriasEscola(){
//        var request = {
//            method: 'get',
//            url: api.url + '/escola/recuperar-categorias-escola'
//        }
//        return $http(request);
//    }
});
(function () {
    'use strict';

   angular.module('cgescola.homologacao').controller('HomologacaoInscricaoController', HomologacaoInscricaoController);

    HomologacaoInscricaoController.$inject = ['$scope', 'homologacaoAPI', 'inscricaoAPI', '$location', '$routeParams', '$window', 'toaster', 'api', '$localStorage', 'dashboardAPI','$http','FileSaver'];

    function HomologacaoInscricaoController($scope, homologacaoAPI, inscricaoAPI, $location, $routeParams, $window, toaster, api, $localStorage, dashboardAPI, $http, FileSaver){
    	var vm = this;
      vm.alterarVisualizacao = true;		
      if($localStorage.isHomologador != undefined) {
        vm.homologador = $localStorage.isHomologador;
         vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined || 
                $localStorage.cdRegional == null ||
                $localStorage.cdRegional == "" ) ? true : false;
      }

      $scope.nomeUsuario = $localStorage.nomeUsuario;
   		
      vm.status = ['Iniciada', 'Enviada', 'Recusada', 'Homologada', 'Aguardando Confirmação'];

      //paginação
      $scope.pagination = {
        current: 1
      };

      $scope.pageChangedEscola = function (newPage) {
       $scope.pagination.current = newPage;
      };

      //redirect para a página de inscrição
    //   vm.redirecionarPaginaInscricao = function(){
    //     $location.path('/inscricao')
    //   }

      //listagem de esolas *utilizado para filtros*.
   		vm.recuperarEscolas = function(_nome,_status){
        //vm.linkExportacaoInscricao = api.url + '/relatorios/'+ $localStorage.categoriaEscola + "/inscricoes.csv";
        //vm.linkExportacaoInscricao = api.url + '/relatorios/inscricoes.csv';
   			homologacaoAPI.recuperarEscolas(_nome,_status, $localStorage.categoriaEscola)
   			.then(function(obj){
   				vm.escolas = obj.data;
          $('#pesquisarEscolas').modal('hide');
          delete $scope.nome;
          delete $scope.status;
   			})
   		}

   		//relatorio das inscicoes de escolas homologadas
   		vm.gerarRelatorioInscricoes = function() {    	  
   			homologacaoAPI.gerarRelatorioInscricoes()        
   	        .then(function(resposta) {
   	        	//$window.alert(resposta.data);   	        	
   	        	var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                FileSaver.saveAs(file, 'inscricoes.csv');   	          
   	        })
   	      }   
              
              
              	//relatorio das inscicoes de escolas Cadastradas
   		vm.gerarRelatorioEscolasCadastradas = function() {    	  
   			homologacaoAPI.gerarRelatorioEscolasCadastradas()        
   	        .then(function(resposta) {
   	        	//$window.alert(resposta.data);   	        	
   	        	var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                FileSaver.saveAs(file, 'escolas-cadastradas.csv');   	          
   	        })
   	      }   
              
              //relatorio das inscicoes de escolas alunos Cadastradas
              	vm.gerarRelatorioEscolasAlunosCadastradas = function(idEscola) {    	  
   			homologacaoAPI.gerarRelatorioEscolasAlunosCadastradas(idEscola)        
   	        .then(function(resposta) {
   	        	//$window.alert(resposta.data);   	        	
   	        	var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                FileSaver.saveAs(file, 'escolas-alunos-cadastradas.csv');   	          
   	        })
   	    } 
            //relatorio das inscicoes de escolas orientadores Cadastradas
              	vm.gerarRelatorioEscolasOrientadoresCadastradas = function(idEscola) {    	  
   			homologacaoAPI.gerarRelatorioEscolasOrientadoresCadastradas(idEscola)        
   	        .then(function(resposta) {
   	        	//$window.alert(resposta.data);   	        	
   	        	var file = new Blob([resposta.data], {type: 'text/csv;charset=utf-8'});
                FileSaver.saveAs(file, 'escolas-orientadores-cadastradas.csv');   	          
   	        })
   	      } 
              
              
              
              
   		
   		
      //detalhamento da escola.
      vm.detalharEscola = function(){    	  
        inscricaoAPI.recuperarEscola($routeParams.idDiretor)        
        .then(function(obj) {        
          vm.escola = obj.data[0];
            console.log("vm.escola -> ", vm.escola);
          //$window.alert(vm.escola.nomeArquivo);
          //vm.caminhoArquivo = api.url + '/1arquivos/baixarArquivo/' + vm.escola.nomeArquivo;
        })
      }

      //lista de alunos para exibição no detalhamento da escola.
      vm.recuperarListaDeAlunos = function(){
        inscricaoAPI.recuperarListaDeAlunos($routeParams.idDiretor)
        .then(function(obj){
          vm.alunos = obj.data;
        })
      }

      //lista de orientadores para exibição no detalhamento da escola.
      vm.recuperarListaDeOrientadores = function(){
        inscricaoAPI.recuperarListaDeOrientadores($routeParams.idDiretor)
        .then(function(obj){
          vm.orientadores = obj.data;
        })
      }

      //lista de séries participantes para exibição no detalhamento da escola.
      vm.recuperarListaDeSeries = function(){
        inscricaoAPI.recuperarListaDeSeries($routeParams.idDiretor)
        .then(function(obj){
          vm.series = obj.data;
        })
      }

        // redirecionar inscrição quando for da SUTCS
        vm.redirecionarParaEdicao = function (cpfDiretor) {
          $location.path('/inscricao/' + cpfDiretor);
      }

      //limpar scope utilizado no filtro de escolas.
   		vm.limparFiltro = function(){
   			delete $scope.nome;
   			delete $scope.status;
       }
       
       vm.cancelarInscricao = function(idEscola) {
         homologacaoAPI.cancelarInscricao(idEscola, $localStorage.usuarioAutenticado)
         .then(function(obj){
           if(obj.data != 'BAD_REQUEST' || obj.data != 'INTERNAL_SERVER_ERROR') {        	   
             toaster.pop({
               type: 'success',
               title: 'A inscrição desta escola foi cancelada com sucesso.',
               body: '',
               showCloseButton: true,
               timeout: 2000});             
               //vm.redirecionarEscolasCadastradas();
           }else{
            toaster.pop({
              type: 'error',
              title: 'Ocorreu um erro ao cancelar a inscrição desta escola.',
              body: '',
              showCloseButton: true,
              timeout: 2000});
           }         
         })
         setTimeout(function() {vm.redirecionarEscolasCadastradas();
        	 
     	}, 1000);
       }

       vm.redirecionarEscolasCadastradas = function() {
         $location.path('/escolas-cadastradas');         
         //$window.location.reload();
         $window.location.close();
       }

       vm.fecharModalCancelamentoInscricao = function(){
         $('#cancelarInscricao').modal('hide');
       }

      //homologar ou recusar inscrição da escola.
      vm.homologarInscricao = function(recusar, motivacao, idEscola,emailDiretor, emailEscola){
        if(recusar == undefined){ recusar = false;}
        homologacaoAPI.homologarInscricao(recusar, motivacao, idEscola,emailDiretor, emailEscola, $localStorage.usuarioAutenticado)
        .then(function(obj){
          vm.main();
          if(recusar){
            toaster.pop({
              type: 'success',
              title: 'A inscrição foi recusada e um email com a motivação foi enviado para o diretor.',
              body: '',
              showCloseButton: true,
              timeout: 5000});
          }else{
            toaster.pop({
              type: 'success',
              title: 'Inscrição homologada com sucesso.',
              body: '',
              showCloseButton: true,
              timeout: 5000});
          }
        })
      }

      //lista de status das escolas *utlizado nos card que numeram a quantidade de escolas por status*.
      vm.listarStatusDasEscolas = function(){
        homologacaoAPI.listarStatusDasEscolas($localStorage.categoriaEscola)
        .then(function(obj){
          vm.qtdStatus = obj.data;
        });
      }

      vm.recuperarNomeResponsavel = function(_cpfDiretor){
        homologacaoAPI.recuperarNomeResponsavel(_cpfDiretor)
        .then(function(obj){        	
          vm.nomeResponsavel = obj.data[0];
        })
      }

      vm.redirecionar = function(){
            $window.localStorage.clear();
            $location.path('/');
      }

      //link para detalhamento da escola.
      vm.irParaDetalhamento = function(_cpfDiretor){
        $location.path('/detalhamento-inscricao/' + _cpfDiretor);
      }

      vm.redirecionarParaAvaliacao = function(idEscola, cpfDiretor){
        $location.path('/avaliacao/' + idEscola + '/'+ cpfDiretor);
      }

      vm.redirecionarParaGerenciamentoAvaliacao = function(){
        $location.path('/gerenciamento-avaliacao');
      }

      vm.recuperarCategoriasEscola = function(){
        homologacaoAPI.recuperarCategoriasEscola()
        .then(function(obj){
          vm.categorias = obj.data;
        })
      }

      vm.gravarCategoriaSelecionada = function(categoria){
        if(categoria != null){
          $localStorage.categoriaEscola = categoria;
          if(categoria == 'Escola Classe'){
            $localStorage.tipoEscola = 1;
          }else{
            $localStorage.tipoEscola = 2;
          }
        }else{
          delete $localStorage.categoriaEscola;
        }
        
      }

      $(document).ready(function(){
            $('[data-toggle="tooltip"]').tooltip();});   

   		vm.main = function(){
   		//Seta o cabeçalho comum as chamadas http com o token para esta controller
   		$http.defaults.headers.common = { 'Authorization' :  $localStorage.headerToken};
   		
         if($localStorage.isDiretor || $localStorage.isOrientador){
           $location.path('/inscricao');
         }

        if($localStorage.usuarioAutenticado == undefined){
          vm.redirecionar();
        }
        	
        if($location.path() == '/detalhamento-inscricao/' + $routeParams.idDiretor){
          vm.detalharEscola();
          //$window.alert($localStorage.headerToken);
          vm.recuperarListaDeSeries();
          vm.recuperarListaDeOrientadores();
          vm.recuperarListaDeAlunos();          
          vm.recuperarNomeResponsavel($routeParams.idDiretor);          
        }else{    	
          vm.recuperarEscolas();
          vm.listarStatusDasEscolas($localStorage.categoriaEscola);
          //delete $localStorage.categoriaEscola;
          //vm.recuperarCategoriasEscola();          
        }
        $localStorage.idEtapa = 2;
   		}

   		vm.main(); 	
    }
})();
(function () {
    'use strict';

   angular.module('cgescola.recurso').controller('GerenciamentoRecursoController', GerenciamentoRecursoController);

    GerenciamentoRecursoController.$inject = ['$scope','$routeParams', 'toaster', 'api','inscricaoAPI', '$localStorage', '$location', 'recursoAPI', '$window','$http'];

    function GerenciamentoRecursoController($scope, $routeParams, toaster,inscricaoAPI, api, $localStorage, $location, recursoAPI, $window,$http){
    	var vm = this;
        vm.respondido = [];
        


        if($localStorage.isHomologador != undefined) {
            vm.homologador = $localStorage.isHomologador;
             vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined || 
                $localStorage.cdRegional == null ||
                $localStorage.cdRegional == "" ) ? true : false;
        }
        
 

        vm.recuperarRecursosPorEscola = function(){
            recursoAPI.recuperarRecursosPorEscola($routeParams.idEscola, $routeParams.idEtapa)
            .then(function(obj){
                vm.recursos = obj.data;
                vm.nomeEscola = obj.data[0].nomeEscola;
            })
        }
        
        vm.salvarRespostaRecurso = function(idRecurso, deferido, motivacao, nomeEtapa, nomeEscola){
            if(deferido == undefined){
                deferido = false;
            }
            if(!motivacao){
                motivacao = "";
            }
            recursoAPI.salvarRespostaRecurso(idRecurso, $localStorage.usuarioAutenticado, deferido,
             motivacao, nomeEtapa, nomeEscola)
            .then(function(obj){
                if(obj.data != "BAD_REQUEST" && obj.data != "INTERNAL_SERVER_ERROR"){
                    //vm.respondido = idRecurso;

                    toaster.pop({
                        type: 'success',
                        title: "Resposta enviada com sucesso!",
                        body: '',
                        showCloseButton: true,
                        timeout: 5000});

                    vm.main();
                }
            })
        }

        vm.verificarRespostaRecurso = function(){
            recursoAPI.verificarRespostaRecurso($routeParams.idEscola, $routeParams.idEtapa)
            .then(function(obj){
                vm.respostasRecurso = obj.data;

                if(vm.respostasRecurso.length > 0){
                    vm.respondido = vm.respostasRecurso[vm.respostasRecurso.length - 1].RECURSOS; 
                }
            })
        }

        vm.redirecionarParaGerenciamentoDaAvaliacao = function(){
            $location.path('/gerenciamento-avaliacao');
        }

        vm.redirecionar = function(){
          $window.localStorage.clear();
          $location.path('/');
        }

        //link para a tela de homologação.
        vm.redirecionarHomologacao = function(){
          $location.path('/inscricoes');
        }
        
        vm.redirecionarRegional = function () {
            $location.path('/regional');
        }

        vm.main = function(){
        	//Seta o cabeçalho comum as chamadas http com o token para esta controller 
    		$http.defaults.headers.common = { 'Authorization' :  $localStorage.headerToken};
            vm.recuperarRecursosPorEscola();
            vm.verificarRespostaRecurso();
        }

        vm.main();
    }
})();
angular.module('cgescola.recurso')
.service('recursoAPI', function ($http, api) {
	return{
		salvarRecurso: salvarRecurso,
		verificarRecurso: verificarRecurso,
		controlarPrazo: controlarPrazo,
		recuperarTotalDeNotas: recuperarTotalDeNotas,
		recuperarPontuacaoMaximaPorEtapa: recuperarPontuacaoMaximaPorEtapa,
		recuperarPontuacaoMaxima: recuperarPontuacaoMaxima,
        recuperarRecursosPorEscola: recuperarRecursosPorEscola,
        salvarRespostaRecurso: salvarRespostaRecurso,
        verificarRespostaRecurso: verificarRespostaRecurso,
        verificarRecursoDeferido: verificarRecursoDeferido,
        recuperarNotaTotalDaEtapa: recuperarNotaTotalDaEtapa
	}

	function salvarRecurso(_idEscola, _idEtapa, _cpfSolicitante, _motivacao){
		 var request = {
            method: 'post',
            url: api.url + '/recurso/salvar-recurso',
            params: {idEscola: _idEscola, idEtapa: _idEtapa, cpfSolicitante: _cpfSolicitante, motivacao: _motivacao}
        }
        return $http(request);
	}

	function verificarRecurso(_idEscola, _idEtapa){
		var request = {
            method: 'get',
            url: api.url + '/recurso/verificar-recurso',
            params: {idEscola: _idEscola, idEtapa: _idEtapa}
        }
        return $http(request);
	}

	function controlarPrazo(_idEscola, _idEtapa){
		var request = {
            method: 'get',
            url: api.url + '/recurso/controlar-prazo-recurso',
            params: {idEscola: _idEscola, idEtapa: _idEtapa}
        }
        return $http(request);
	}

	function recuperarTotalDeNotas(_idEscola){
		var request = {
            method: 'get',
            url: api.url + '/recurso/recuperar-nota-total',
            params: {idEscola: _idEscola}
        }
        return $http(request);
	}

	/*function recuperarPontuacaoMaximaPorEtapa(_idEtapa, _idTipoEscola){
		var request = {
            method: 'get',
            url: api.url + '/recurso/recuperar-total-possivel-por-etapa',
            params: {idEtapa: _idEtapa, tipoEscolaId: _idTipoEscola}
        }
        return $http(request);
		
	}
	*/
	function recuperarPontuacaoMaximaPorEtapa(_idEtapa){
		var request = {
            method: 'get',
            url: api.url + '/recurso/recuperar-total-possivel-por-etapa',
            params: {idEtapa: _idEtapa}
        }
        return $http(request);
		
	}

	function recuperarPontuacaoMaxima(){
		var request = {
            method: 'get',
            url: api.url + '/recurso/recuperar-total-possivel',
        }
        return $http(request);	
	}
    
    function recuperarRecursosPorEscola(idEscola, idEtapa){
        var request = {
            method: 'get',
            url: api.url + '/recurso/listar-recursos',
            params: {idEscola: idEscola, idEtapa: idEtapa}
        }
        return $http(request);
    }

    function salvarRespostaRecurso(idRecurso, cpfAvaliador, deferido, motivacao, nomeEtapa, nomeEscola){
        var request = {
            method: 'post',
            url: api.url + '/recurso/salvar-resposta-recurso',
            params: {idRecurso: idRecurso, cpfAvaliador: cpfAvaliador,
                 deferido: deferido, motivacao: motivacao,
                 nomeEtapa: nomeEtapa, nomeEscola: nomeEscola}
        }
        return $http(request);
    }

    function verificarRespostaRecurso(idEscola, idEtapa){
        var request = {
            method: 'get',
            url: api.url + '/recurso/verificar-resposta-recurso',
            params: {idEscola: idEscola, idEtapa: idEtapa}
        }
        return $http(request);
    }

    function verificarRecursoDeferido(idEscola, idEtapa){
        var request = {
            method: 'get',
            url: api.url + '/recurso/verificar-recurso-deferido',
            params: {idEscola: idEscola, idEtapa: idEtapa}
        }
        return $http(request);
    }

    //ALTERAÇÕES

    function recuperarNotaTotalDaEtapa(idEscola, idEtapa){
        var request = {
            method: 'get',
            url: api.url + '/recurso/recuperar-nota-total-etapa',
            params: {idEscola: idEscola, idEtapa: idEtapa}
        }
        return $http(request);   
    }

});
(function () {
    'use strict';

   angular.module('cgescola.recurso').controller('RecursoController', RecursoController);

    RecursoController.$inject = ['$scope', 'recursoAPI','$routeParams', 'toaster', 'api', '$localStorage', 'avaliacaoAPI', '$location', '$timeout', '$window','$http'];

    function RecursoController($scope, recursoAPI, $routeParams, toaster, api, $localStorage, avaliacaoAPI, $location, $timeout, $window,$http){
        var vm = this;
        
        if($localStorage.isHomologador != undefined) {
            vm.homologador = $localStorage.isHomologador;
             vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined || 
                $localStorage.cdRegional == null ||
                $localStorage.cdRegional == "" ) ? true : false;
        }

        vm.naoAvaliada = false;
        vm.totalPorEtapa = 0;
        vm.total = 0;
        vm.respondido = false;

        vm.verificarAvaliacaoFinalizada = function(idEtapa){
            avaliacaoAPI.verificarAvaliacaoFinalizada($routeParams.idEscola, idEtapa)
            .then(function(obj){
                $scope.avaliacao = {};
                vm.avaliacao = obj.data;
                
                if(vm.avaliacao.length == 0){
                    vm.naoAvaliada = true;
                }else{
                    vm.naoAvaliada = false;
                }

                $(vm.avaliacao).each(function(i){
                    var a = vm.avaliacao[i];
                    $scope.avaliacao[a.atividadeId] = a.nota.toFixed(2);
                })

                vm.recuperarNotaTotalDaEtapa(idEtapa);
                
            })
        }

        vm.verificarRecurso = function(etapaId){
            recursoAPI.verificarRecurso($routeParams.idEscola, etapaId)
            .then(function(obj){
                vm.qtdRecurso = obj.data;
            })
        }

        vm.salvarRecurso = function(motivacao){
            recursoAPI.salvarRecurso($routeParams.idEscola, vm.etapaId, $localStorage.usuarioAutenticado, motivacao)
            .then(function(obj){
                if(obj.data == "OK"){
                    $("#recursoModal").modal('hide');

                    vm.limparScope();
                    
                    toaster.pop({
                        type: 'success',
                        title: 'Recurso enviado com sucesso.',
                        body: '',
                        showCloseButton: true,
                        timeout: 5000});

                    vm.verificarRecurso(vm.etapaId);
                    vm.controlarPrazo(vm.etapaId);
                    vm.recuperarPontuacaoMaximaPorEtapa(vm.etapaId);                 
                }
            })
        }

        vm.controlarPrazo = function(idEtapa){
            recursoAPI.controlarPrazo($routeParams.idEscola, idEtapa)
            .then(function(obj){
                vm.prazoEsgotado = obj.data.PRAZO_ESGOTADO;
             
                vm.dataLimite = obj.data.DATA_LIMITE;
            })
        }

        vm.verificarRespostaRecurso = function(idEtapa){
            recursoAPI.verificarRespostaRecurso($routeParams.idEscola, idEtapa)
            .then(function(obj){
                vm.respostas = obj.data;
                vm.motivacao = obj.data[0].MOTIVACAO;
                vm.resposta = obj.data[0].RESPOSTA;
            })
        }

    	vm.redirecionarParaInscricao = function(){
    		$location.path('/inscricao');
    	}

        vm.limparScope= function(){
            delete $scope.txtMotivacao;
        }

        //logout
        vm.redirecionar = function(){
            $window.localStorage.clear();
            $location.path('/');
        }

        //link para a tela de homologação.
        vm.redirecionarHomologacao = function(){
          $location.path('/inscricoes');

        }
        
         vm.redirecionarRegional = function () {
            $location.path('/regional');
        }

  /*      vm.listarEtapasPorTipoDeEscola = function(){
            avaliacaoAPI.listarEtapasPorTipoDeEscola($localStorage.tipoEscola)
            .then(function(obj){
                vm.etapas = obj.data;
            })
        }
        */
        
        vm.listarTodasEtapas = function(){
            avaliacaoAPI.listarTodasEtapas($localStorage.tipoEscola)
            .then(function(obj){
                vm.etapas = obj.data;
            })
        }
        
        vm.listarTodasEtapas = function(){
            avaliacaoAPI.listarTodasEtapas()
            .then(function(obj){
                vm.etapas = obj.data;
            })
        }


        vm.listarControleDeAvaliacao = function (idEtapa, diferenciada) {
            avaliacaoAPI.listarControleDeAvaliacao(idEtapa)
                .then(function (obj) {
                    vm.controle = obj.data;
                    vm.descricaoEtapa = vm.controle[0].etapa.descricao;
                    vm.etapaDiferenciada = diferenciada;
                    $localStorage.idEtapa = idEtapa;
                    vm.etapaId = $localStorage.idEtapa;
                    vm.verificarAvaliacaoFinalizada(idEtapa);
                })
        }

        vm.recuperarTotalDeNotas = function(){
            recursoAPI.recuperarTotalDeNotas($routeParams.idEscola)
            .then(function(obj){
                vm.total = obj.data
            })
        }

        vm.recuperarPontuacaoMaxima = function(){
            recursoAPI.recuperarPontuacaoMaxima()
            .then(function(obj){
                vm.pontualMaximaPossivel = obj.data;
            })
        }

        vm.recuperarPontuacaoMaximaPorEtapa = function(idEtapa){
            recursoAPI.recuperarPontuacaoMaximaPorEtapa(idEtapa, $localStorage.tipoEscola)
            .then(function(obj){
               /* vm.pontuacaoMaximaEtapa = obj.data;*/
//                if(idEtapa == 4 || idEtapa == 2 ){
//                	vm.pontuacaoMaximaEtapa = 10;
//                }else if(idEtapa == 3){
//                	vm.pontuacaoMaximaEtapa = 30;
//                }else if(idEtapa == 5){
//                	vm.pontuacaoMaximaEtapa = 35;
//                }else if(idEtapa == 6){
//                	vm.pontuacaoMaximaEtapa = 15;
//                }
                    if(idEtapa == 2 ){
                    	vm.maximoPontuacaoPorEtapa = 100;
                    }else if(idEtapa == 4){
                        vm.maximoPontuacaoPorEtapa = 200;
                    }else if(idEtapa == 3){
                    	vm.maximoPontuacaoPorEtapa = 300;
                    }else if(idEtapa == 5){
                    	vm.maximoPontuacaoPorEtapa = 150;
                    }else if(idEtapa == 6){
                    	vm.maximoPontuacaoPorEtapa = 250;
                    }
            })
        }

        vm.recuperarNotaTotalDaEtapa = function(idEtapa){
            recursoAPI.recuperarNotaTotalDaEtapa($routeParams.idEscola, idEtapa)
            .then(function(obj){
                vm.totalPorEtapa = obj.data;
                if(!vm.totalPorEtapa){
                    vm.totalPorEtapa = 0;
                }
            })
        }

    	vm.main = function(){
    		//Seta o cabeçalho comum as chamadas http com o token para esta controller 
    		$http.defaults.headers.common = { 'Authorization' :  $localStorage.headerToken};
            vm.recuperarTotalDeNotas();  
            vm.recuperarPontuacaoMaxima();
            vm.verificarRecurso(2);
            vm.controlarPrazo(2);
            vm.recuperarPontuacaoMaximaPorEtapa(2);
            vm.verificarRespostaRecurso(2);  

            /*vm.listarEtapasPorTipoDeEscola();*/
            vm.listarTodasEtapas();
           /* vm.listarControleDeAvaliacao(1, false);*/
            vm.listarControleDeAvaliacao(2);
    	}
        
        vm.main(); 
        
    }
})();
(function(){
  'use strict';

  angular.module('cg-escola.autenticacao')
    .factory('responseQueue', responseQueue);

  /* @ngInject */
  function responseQueue($injector){

    var queue = [];

    return {
      enqueue: enqueue,
      retryAll: retryAll,
      rejectAll: rejectAll
    };

    function enqueue(response, deferred){
      queue.push({
        response: response,
        deferred: deferred
      });
    }

    function retryAll(requestModifier){
      var modifier = autoReturnDecorator(requestModifier);

      // Regular for loop used for best performance
      for(var i = 0; i < queue.length; i++){
        // Change the request config accordingly to user's given function 
        modifier(queue[i].response.config);
        // Then make the request again with the new config
        retryRequest(queue[i].response.config, queue[i].deferred);
      }
      queue = [];
    }

    function rejectAll(reason){
      for(var i = 0; i < queue.length; i++){
        queue[i].deferred.reject(reason);
      }
      queue = [];
    }

    function retryRequest(config, deferred){
      // Lazy instantiation to avoid circular dependencies
      var $http = $http || $injector.get('$http');

      $http(config).then(function(response){
        deferred.resolve(response);
      }, function(reason){
        deferred.reject(reason);
      });
    }

    function autoReturnDecorator(modifier){
      return function(config){
        modifier(config);
        return config;
      };
    }
  }
})();
(function(){
  'use strict';

  angular.module('cg-escola.autenticacao').factory('TokenInterceptor', TokenInterceptor);

  /** @ngInject */
  function TokenInterceptor($q, TokenService, responseQueue, $rootScope){
    return {
      request: requestInterceptor,
      responseError: responseErrorInterceptor
    };

    function requestInterceptor(config){
      return TokenService.getToken().then(function(tokenData){
        var bearerToken = tokenData.tokenType + ' ' + tokenData.accessToken;
        config.headers['Authorization'] = bearerToken;
        return config;
      }, function(reason){
        return config;
      });
    }

    function responseErrorInterceptor(response){
      if(isInvalidTokenError(response)){
        return handleInvalidTokenResponse(response);
      }
      return $q.reject(response);
    }

    function handleInvalidTokenResponse(response){
      var deferred = $q.defer();
      responseQueue.enqueue(response, deferred);
      $rootScope.$broadcast('auth:invalid-token-request');
      return deferred.promise;
    }

    function isInvalidTokenError(response){
      var res = angular.copy(response);
      var responseHasData = angular.isObject(res.data) && !!res.data;
      var hasError = responseHasData ? ('error' in res.data) : false;
      var error = hasError ? res.data.error : false;
      return error === 'invalid_token';
    }
  }
})();
(function(){
  'use strict';

  angular.module('cg-escola.autenticacao').factory('TokenService', TokenService);

  /** @ngInject */
  function TokenService($httpParamSerializer, $q, api, $localStorage){

    var clientId = 'cg-escola-df';
    var clientSecret = 'secret'; 

    return {
      getToken: getToken,
      getTokenFromRemote: getTokenFromRemote,
      cleanToken: cleanToken,
      tokenRequest: {
        isLocked: isTokenRequestLocked,
        lock: lockTokenRequest,
        release: releaseTokenRequest
      }
    };

    function getToken(){
      if(angular.isDefined($localStorage.auth) 
         && angular.isDefined($localStorage.auth.token)){
        return $q.resolve($localStorage.auth.token);
      }else{
        return getTokenFromRemote();
      }
    }

    function getTokenFromRemote(){
      return requestToken().then(function(tokenData){
        saveTokenInLocalStorage(tokenData);
        return tokenData;
      });
    }

    function cleanToken(){
      if(angular.isDefined($localStorage.auth)){
        delete $localStorage.auth.token;
      }
    }

    function isTokenRequestLocked(){
      checkLocalStorageAuthObject();
      return $localStorage.auth.tokenAlreadyRequested;
    }

    function lockTokenRequest(){
      checkLocalStorageAuthObject();
      $localStorage.auth.tokenAlreadyRequested = true;
    }

    function releaseTokenRequest(){
      checkLocalStorageAuthObject();
      $localStorage.auth.tokenAlreadyRequested = false;
    }

    function checkLocalStorageAuthObject(){
      if(!angular.isDefined($localStorage.auth)){
        $localStorage.auth = {
          token: undefined,
          tokenAlreadyRequested: false
        };
      }
    }

    function saveTokenInLocalStorage(tokenData){
      checkLocalStorageAuthObject();
      $localStorage.auth.token = tokenData;
    }

    function requestToken(){
      var data = $httpParamSerializer({
        grant_type: 'client_credentials'
      });
      var url = api.url + '/oauth/token';

      var defer = $q.defer();

      // Ajax was used because this request needs to go unauthorized 
      // and Angular $http is configured with an authorization interceptor
      // that calls this function to get a new token (circular dependency)
      $.ajax({
        type: 'POST',
        beforeSend: setTokenRequestHeaders,
        url: url,
        data: data,
        success: function(response){
          defer.resolve(getTokenSuccessHandler(response));
        },
        error: function(reason){
          defer.reject(getTokenErrorHandler(reason));
        }
      });

      return defer.promise;
    }

    function setTokenRequestHeaders(request){
      var clientEncoded = btoa(clientId + ':' + clientSecret);
      request.setRequestHeader(
        'Authorization', 'Basic ' + clientEncoded
      );
      request.setRequestHeader(
        'Content-type', 'application/x-www-form-urlencoded; charset=utf-8'
      );
    }

    function getTokenSuccessHandler(response){
      var data;
      if(!('error' in response)){
        var scopes = response.scope.split(' ');
        var tokenData = {
          accessToken: response.access_token,
          tokenType: response.token_type,
          ttl: response.expires_in,
          scopes: scopes
        };
        data = tokenData;
      }else{
        var errorDesc = 'error_description' in response 
          ? response.error_description
          : '';
        var errorData = {
          error: response.error,
          description: errorDesc
        }
        data = errorData;
      }
      return data;
    }

    function getTokenErrorHandler(reason){
      return $q.reject(reason);
    }
  }
})();
/*eslint-disable */
(function () {
  'use strict';

  angular.module('cgescola').controller('DashboardController', DashboardController);

  /** @ngInject */
  //DashboardController.$inject = ['toaster'];
  function DashboardController($scope, $localStorage, dashboardAPI, $location, $routeParams, $window,FileSaver, $rootScope, toaster,$http) {
    var vm = this;
    vm.diretorOrientador = null;
    vm.desabilitaAcesso = true;
    if ($localStorage.isHomologador != undefined) {
      vm.homologador = $localStorage.isHomologador;
       vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined || 
                $localStorage.cdRegional == null ||
                $localStorage.cdRegional == "" ) ? true : false;
    }
    
    vm.teste = function (){
         dashboardAPI.teste().then(function (resposta) {
                        var file = new Blob([resposta.data], { type: 'text/csv;charset=utf-8' });
                        FileSaver.saveAs(file, 'avaliacoes-escola.csv');
                })
    }


    vm.geraSenha = function(cpf) {
    	// Verifica se o CPF foi digitado 
    	if (cpf!= undefined && cpf!= "") {    		
    		dashboardAPI.geraSenha(cpf)
    		.then(function (obj) {
    			if (obj.data != "") {    				
    				toaster.pop({
    					type: 'success',
    					title: 'Senha gerada com sucesso !!\nFavor verificar o seu email:' ,
    					body: obj.data[3],
    					showCloseButton: true,
    					timeout: 5000
    				});    				
    			} else {
    				toaster.pop({
    					type: 'error',
    					title: 'Erro ao gerar senha para o usuario',
    					body: 'Usuário nao autorizado !!',
    					showCloseButton: true,
    					timeout: 6000
    				});
    				//$window.alert("Erro ao gerar senha para o usuario \n");
    			}
    		})
    	} else { // CPF nao digitado    		
    		toaster.pop({
    			type: 'info',
    			title: 'CPF não informado',
    			body: 'Favor informar o CPF !!',
    			showCloseButton: true,
    			timeout: 6000
    		});
    	}
    }

	
    //Realiza login retornando token de acesso
    vm.login = function(cpf,senha) {
		$localStorage.nomeUsuario = "";
        $localStorage.usuarioAutenticado = "";
        $localStorage.isHomologador = false;
        $localStorage.isDiretor = false;
        $localStorage.isOrientador = false;
        $localStorage.isDiretorEOrientador = false;
        $localStorage.idDiretor = "";
        $localStorage.headerToken = "";
		dashboardAPI.autenticaEPegaToken(cpf,senha)
    	.then(function(obj) {    		
    		var bearerToken = obj.data.token_type + ' ' + obj.data.access_token;            
    		$localStorage.headerToken = bearerToken;    		
    		//Seta o cabeçalho comum as chamadas http com o token para esta controller 
    		$http.defaults.headers.common = { 'Authorization' :  $localStorage.headerToken};
    		vm.isDiretor(cpf);
    	}         
		,function(obj) {			
			//$window.alert(obj.data.error_description);
			if(obj.data.error =="unauthorized") {
				vm.error = "Usuario nao autorizado.";
			} else if (obj.data.error =="invalid_grant") {
				vm.error = "O CPF ou senha incorretos.";
			}			       
			});
    		
    }
    //verifica se o cpf informado é de algum professor indicado como orientador
    vm.isOrientador = function (cpf) {
      dashboardAPI.isOrientador(cpf)
        .then(function (obj) {
          if (obj.data != "") {
              if(vm.desabilitaAcesso){
                vm.error = "Usuário não autorizado.";
            }else{
                $localStorage.isOrientador = true;
                $localStorage.usuarioAutenticado = cpf;
                            $localStorage.idDiretor = obj.data[3];						
                            $localStorage.nomeUsuario=obj.data[2];			
                $location.path('/inscricao');                
            }
          } else {			  
            vm.isHomologador(cpf);
          }
        })
    }

    vm.isDiretoreOrientador = function(cpf){
      dashboardAPI.isOrientador(cpf)
      .then(function (obj){
        if(obj.data.length > 0) {
             if(vm.desabilitaAcesso){
                   vm.error = "Usuário não autorizado.";
             }else{
		  vm.diretorOrientador = obj.data;
		  //$window.alert("diretoOrientador "+obj.data);
             }
        } else {
          vm.diretorOrientador = null;
        }
      })
    } 

    //verifica se o cpf informado é de algum diretor de escola pública de Goias.
    vm.isDiretor = function (cpf) {
      vm.isDiretoreOrientador(cpf);
      dashboardAPI.isDiretor(cpf)
        .then(function (obj) {
			//$window.alert("diretor "+obj.data[1]);          
			if (obj.data!="") {
	            $localStorage.usuarioAutenticado = cpf;
	            if (vm.diretorOrientador == null) {
                        if(vm.desabilitaAcesso){
                                vm.error = "Usuário não autorizado.";
                        }else{
                            $localStorage.nomeUsuario = obj.data[2];
                            $localStorage.isDiretor = true;
                            $location.path('/inscricao');            
                        }
	            } else {// Trata-se de um diretor que se cadastrou tambem como orientador
	            	$localStorage.isDiretorEOrientador = true; // esta variavel é utilizada na controller "incricaoCtrl.js" para habilitar/desabilitar campos de edicao. 
	            	vm.isOrientador(cpf);  
	            }
          } else {
			//$window.alert("deu merda esmo ");
            vm.isOrientador(cpf);
          } 
        })
    }

    //verifica se o cpf indicado é de algum servidor/homologador da CGE.
    vm.isHomologador = function (cpf) {
      dashboardAPI.isHomologador(cpf)
        .then(function (obj) {
          //if (obj[0].OK) {			  
			  if (obj.data!="") {
            $localStorage.nomeUsuario = obj.data[2];
            $localStorage.usuarioAutenticado = cpf;
            $localStorage.isHomologador = true;
            $localStorage.isDiretor = false;
            $localStorage.cdRegional = obj.data[4];
           
            $location.path('/inscricoes');
          } else {
            $rootScope.$apply(function () {
              vm.error = "O CPF informado não tem permissão para acessar o sistema";
            });
          }
        })
    }

    //logout
    vm.redirecionar = function () {
      $window.localStorage.clear();
      $location.path('/');
    }

    //link para a tela de homologação.
    vm.redirecionarHomologacao = function () {
      $location.path('/inscricoes');
    }
    //link para a tela de coordenador regional.
       vm.redirecionarRegional = function () {
            $location.path('/regional');
        }
  }

})();
angular.module('cgescola.inscricao')
.factory('dashboardAPI', function ($http, api,$window,$httpParamSerializer, $localStorage) {
	return{
		login:login,
		geraSenha:geraSenha,
        isDiretor:isDiretor,
        confirmarPreInscricao: confirmarPreInscricao,
        isHomologador: isHomologador,
        isOrientador: isOrientador,
        recuperarNomeDoUsuario: recuperarNomeDoUsuario,
        autenticaEPegaToken:autenticaEPegaToken,
        teste:teste
	};

    // function login(_cpf,senha){
    //     	var request = {
    //     		method: 'post',
    //     		headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //     		url: api.url + '/autenticar/login-sigrh',
    //     		params: {cpf:_cpf,senha:senha}
    //     	}
    //     	return $http(request);
	// }
	
	
	//Gera a senha pra primeiro acesso ou no caso de esquecimento da mesma
	function geraSenha(_cpf){
//		var data = $httpParamSerializer({
//	        grant_type: 'client_credentials'
//	      });
//		var clientId = 'cg-escola-goias';
//		var clientSecret = 'secret'; 
//		var clientEncoded = btoa(clientId + ':' + clientSecret);
        	var request = {
        		method: 'post',
        		headers: {
        			//'Authorization': 'Basic ' + clientEncoded,
                    'Content-Type': 'application/json'
                },
        		url: api.url + '/autenticar/gerar-senha',
        		//data:data,
        		params: {cpf:_cpf}
        	}
        	return $http(request);
	}
        
        function teste(){
                    var request = {
                    method: 'get',
                    url: api.url + '/relatorios/escolas-notas-atividades.csv'
                 }
                return $http(request);
        
        }

	
	
	function autenticaEPegaToken(cpf,senha) {		
//		var data = $httpParamSerializer({
//	        grant_type: 'client_credentials'
//	      });
		var data = $httpParamSerializer({
	        grant_type: 'password', username : cpf, password : senha
	      });
		
		var clientId = 'cg-escola-goias';
		var clientSecret = 'secret'; 
		var clientEncoded = btoa(clientId + ':' + clientSecret);
                
		var request = {
        		method: 'post',
        		headers: {
        			'Authorization': 'Basic ' + clientEncoded,
//        			'Authorization': 'Basic ' + clientId + ':' + clientSecret,
        			'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
                },
        		
        		url: api.url + '/oauth/token',
        		data:data
        	}
		
	
	//window.alert("BTOAasa "+clientEncoded);
	
//    request.setRequestHeader(
//      'Authorization', 'Basic ' + clientEncoded
//    );
//    request.setRequestHeader(
//      'Content-type', 'application/x-www-form-urlencoded; charset=utf-8'
//    );    
    
    return $http(request);
	}
	
	
	
	function login(_cpf,senha){	
		
		//window.alert($localStorage.headerToken);
        	var request = {
        		method: 'post',
        		headers: {
        			//'Authorization': $localStorage.headerToken,
                    'Content-Type': 'application/json'
                },
        		url: api.url + '/autenticar/login-local',
        		params: {cpf:_cpf,senha:senha}
        	}
        	return $http(request);
    }

    function isOrientador(_cpf){
        var request = {
                method: 'get',
                headers: {
                	//'Authorization': $localStorage.headerToken,
                    'Content-Type': 'application/json'
                },
                url: api.url + '/autenticar/verificar-orientador',
                params: {cpf:_cpf}
            }
            return $http(request);
    }

    function isDiretor(_cpf){		
		
		var request = {
                method: 'get',
                headers: {
                	//'Authorization': $localStorage.headerToken,
                    'Content-Type': 'application/json'
                },
                url: api.url + '/autenticar/verificar-diretor',
                params: {cpf:_cpf}
            }
            return $http(request);


    //    return  $.get("",{cpfServidor:_cpf});
    }

    function isHomologador(_cpf){
		
		var request = {
                method: 'get',
                headers: {
                	//'Authorization': $localStorage.headerToken,
                    'Content-Type': 'application/json'
                },
                url: api.url + '/autenticar/verificar-homologador',
                params: {cpf:_cpf}
            }
            return $http(request);
       
    }

    function confirmarPreInscricao(idEscola,token){
        var request = {
            method: 'post',
            headers: {
            	//'Authorization': $localStorage.headerToken,
                'Content-Type': 'application/json'
            },
            url: api.url + '/escola/confirmar-pre-inscricao',
            params: {idEscola: idEscola,token:token}
        
        }
        return $http(request);
    }

    function recuperarNomeDoUsuario(cpf){
        return $.get('', {cpfServidor: cpf})
    }
});
/*eslint-disable */
(function(){
  'use strict';

  angular.module('cgescola').controller('MainController', MainController);

  /** @ngInject */
  function MainController($scope, APP, localStorageService) {
    $scope.version = APP.version;
    $scope.scrollTop = 140;

    $scope.theme_colors = [
      'pink','red','purple','indigo','blue',
      'light-blue','cyan','teal','green','light-green',
      'lime','yellow','amber','orange','deep-orange'
    ];

    var theme = {
      color: 'theme-light-blue',
      template: 'theme-template-light'
    };

    localStorageService.set('theme', theme);
    localStorageService.bind($scope, 'theme');

    // Setting moment locale to PT-BR
    moment.locale('pt-br');
  };

})();
angular.module('cgescola.coordenadorRegional')
.service('coordenadorRegionalAPI', function ($http, api,$localStorage) {
 	return {
        salvarCoordenadorRegional: salvarCoordenadorRegional,
        recuperarListaHomologadores: recuperarListaHomologadores
        
    };


    function salvarCoordenadorRegional(idHomologador, cpfHomologador, nomeHomologador, emailHomologador, codRegional, nomeRegional){
        var request = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            url: api.url + '/regional/salvar-coordenador-regional',
            params: {
                idHomologador: idHomologador,
                cpfHomologador:cpfHomologador
            ,nomeHomologador:nomeHomologador,emailHomologador:emailHomologador,codRegional:codRegional, nomeRegional: nomeRegional
        }
    }
        return $http(request);
    }
    
    function recuperarListaHomologadores(){
        var request = {
            method: 'get',
            url: api.url + '/regional/homologadores'
        }
        return $http(request);
    }
    
    
});
(function () {
    'use strict';

   angular.module('cgescola.coordenadorRegional').controller('CoordenadorRegionalController', CoordenadorRegionalController);

    CoordenadorRegionalController.$inject = ['$scope', 'homologacaoAPI', 'inscricaoAPI','coordenadorRegionalAPI', '$location', '$routeParams', '$window', 'toaster', 'api', '$localStorage', 'dashboardAPI','$http','FileSaver'];

    function CoordenadorRegionalController($scope, homologacaoAPI, inscricaoAPI,coordenadorRegionalAPI, $location, $routeParams, $window, toaster, api, $localStorage, dashboardAPI, $http, FileSaver){
      var vm = this;
      vm.regional = {};
      $scope.regional = {
          idHomologador:'',
          cpfHomologador: '',
          nomeHomologador: '',
          emailHomologador: '',
          regionais:[{
              codigo: '',
              nome:''
          }], 
          regional: {
              codigo: '',
              nome:''
          }
      }
      $scope.regionalSelected = ''
     $scope.regionalTodasSelected = true;
      
      vm.alterarVisualizacao = true;		
      if($localStorage.isHomologador != undefined) {
        vm.homologador = $localStorage.isHomologador;
        vm.homologadorTodasRegionais = ($localStorage.cdRegional == undefined || 
                $localStorage.cdRegional == null ||
                $localStorage.cdRegional == "" ) ? true : false;
        
      }

      $scope.nomeUsuario = $localStorage.nomeUsuario;
   		
      vm.homologadores = [{ }]

       $scope.pagination = {
            current: 1
        };

        $scope.pageChangedCoordenadorRegional = function (newPage) {
            $scope.pagination.current = newPage;
        };

        $scope.paginationCoordenadorRegional = {
            current: 1
        };

        $scope.pageChangedCoordenadorRegional = function (newPage) {
            $scope.paginationCoordenadorRegional.current = newPage;
        };

      vm.abrirModelNovoHomologador = function(){
          vm.limparFormulario();
          $('#modalFormCoordenadorRegional').modal('show');
      }
              
        vm.redirecionarParaEdicao = function (homologador) {
          $scope.regional.idHomologador = homologador.id;  
          $scope.regional.cpfHomologador = homologador.cpf;
          $scope.regional.nomeHomologador = homologador.nome;
          $scope.regional.emailHomologador = homologador.email;
          
          $scope.regional.regionais.forEach(function(regional){
              
                if(regional.nome === homologador.nomeRegional){
                    $scope.regionalSelected = regional.nome;
                        $scope.regional.regional = {
                            codigo: regional.codigo,
                            nome:regional.nome
                }
                
             }else if(homologador.nomeRegional == "" && homologador.cdRegional == null){
                       $scope.regionalSelected = true
                        $scope.regional.regional = {
                            codigo: "",
                            nome:""
                        }
             }
          })
          
           $('#modalFormCoordenadorRegional').modal('show');
          
      }

        vm.recuperarNomeResponsavel = function(_cpfDiretor){
        homologacaoAPI.recuperarNomeResponsavel(_cpfDiretor)
        .then(function(obj){        	
          vm.nomeResponsavel = obj.data[0];
        })
      }

      vm.salvarCoordenadorRegional = function(idCoordenador,cpfCoordenador, nomeCoordenador, emailCoordenador, regional){
          if(regional == undefined || (regional.codigo == "" && regional.nome == "")){
              regional = {
                  codigo: null,
                  nome: null
              };
              
          }
          coordenadorRegionalAPI.salvarCoordenadorRegional(idCoordenador,cpfCoordenador, nomeCoordenador, emailCoordenador, regional.codigo, regional.nome)
                  .then(function(obj){
                      
           if(obj.data == "ALREADY_REPORTED" ){
                    toaster.pop({
                       type: 'success',
                       title: 'O cadastro foi alterado com sucesso. Um email foi enviado para ' + emailCoordenador,
                       body: '',
                       showCloseButton: true,
                       timeout: 5000});
                     vm.limparFormulario();
                      $('#modalFormCoordenadorRegional').modal('hide');
               }else if(obj.data == "CONTINUE") {
                    toaster.pop({
                       type: 'success',
                       title: 'O cadastro foi realizado com sucesso. Um email foi enviado para ' + emailCoordenador,
                       body: '',
                       showCloseButton: true,
                       timeout: 5000});

                        vm.limparFormulario();
                        $('#modalFormCoordenadorRegional').modal('hide');

            }else if(obj.data == "OK"){
               toaster.pop({
                  type: 'success',
                  title: 'O cadastro foi alterado com sucesso',
                  body: '',
                  showCloseButton: true,
                  timeout: 5000});
                 vm.limparFormulario();
                $('#modalFormCoordenadorRegional').modal('hide');                
            }else if(obj.data == "INTERNAL_SERVER_ERROR"){
                console.log(obj)
                 $('#modalFormCoordenadorRegional').modal('hide');
            }
          });
      }
  
  
      vm.limparFormulario = function(){
          $scope.regional.idHomologador = '';
          $scope.regional.cpfHomologador= '';
          $scope.regional.nomeHomologador= '';
          $scope.regional.emailHomologador = '';
          vm.recuperarRegionais();
          vm.recuperarListaHomologadores();
      }
      
      
      vm.recuperarRegionais = function () {
            inscricaoAPI.recuperarRegionais()
                .then(function (obj) {
                     $scope.regional.regionais = obj.data;
                })
        
       }
      vm.recuperarListaHomologadores = function(){
          coordenadorRegionalAPI.recuperarListaHomologadores()
                  .then(function (obj){
                      vm.homologadores = obj.data;
          });
      }
        

      $(document).ready(function(){
            $('[data-toggle="tooltip"]').tooltip();});   

   		vm.main = function(){
   		//Seta o cabeçalho comum as chamadas http com o token para esta controller
                    $http.defaults.headers.common = { 'Authorization' :  $localStorage.headerToken};
                    vm.recuperarRegionais();
                    vm.recuperarListaHomologadores();
                       toaster.pop({
              type: 'success',
              title: 'O cadastro foi realizado e um email com a acesso foi enviado para o coordenador cadastrado.',
              body: '',
              showCloseButton: true,
              timeout: 5000});
   		}

   		vm.main(); 	
    }
})();